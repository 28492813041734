<template>
  <v-footer dark padless>
    <div class="m-footer-topo">
      <v-container>
        <v-row>
          <v-col>
            <div class="m-footer-title">
              <div class="m-title">
                <span>
                  <strong>Clube</strong>
                </span>
              </div>
              <div class="m-border"></div>
            </div>
            <div class="m-footer-link">
              <v-list>
                <v-list-group
                    no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>História</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item class="m-link-menu" link to="/fundacao">
                    <v-list-item-content>
                      <v-list-item-title>Fundação</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="m-link-menu" link to="/arena">
                    <v-list-item-content>
                      <v-list-item-title>Arena</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="m-link-menu" link to="/simbolos">
                    <v-list-item-content>
                      <v-list-item-title>Nossos Símbolos</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="m-link-menu" link to="/hinos">
                    <v-list-item-content>
                      <v-list-item-title>Nosso Hino</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-item class="m-link-menu" link to="/presidentes">
                  <v-list-item-title>Presidentes</v-list-item-title>
                </v-list-item>
                <v-list-item class="m-link-menu" link to="/gestao#conselho-administrativo">
                  <v-list-item-title>Conselho Administrativo</v-list-item-title>
                </v-list-item>
                <v-list-item class="m-link-menu" link to="/gestao#conselho-deliberativo">
                  <v-list-item-title>Conselho Deliberativo</v-list-item-title>
                </v-list-item>
                <v-list-item class="m-link-menu" link to="/gestao#estatuto">
                  <v-list-item-title>Estatuto</v-list-item-title>
                </v-list-item>
                <v-list-item class="m-link-menu" link to="/editais">
                  <v-list-item-title>Editais</v-list-item-title>
                </v-list-item>
                <v-list-item class="m-link-menu" link to="/arena#m-mapa">
                  <v-list-item-title>Como chegar na Arena</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
          <v-col>
            <div class="m-footer-title">
              <div class="m-title">
                <span>
                  <strong>Futebol</strong>
                </span>
              </div>
              <div class="m-border"></div>
            </div>
            <div class="m-footer-link">
              <v-list>
                <v-list-group
                    no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Futebol Profissional</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item class="m-link-menu" link to="/elenco/1">
                    <v-list-item-content>
                      <v-list-item-title>Elenco</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="m-link-menu" link to="/jogos/1">
                    <v-list-item-content>
                      <v-list-item-title>Jogos</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </div>
          </v-col>
          <v-col>
            <div class="m-footer-title">
              <div class="m-title">
                <span>
                  <strong>Torcida</strong>
                </span>
              </div>
              <div class="m-border"></div>
            </div>
            <div class="m-footer-link">
              <v-list>
                <v-list-item class="m-link-menu" link href="https://api.whatsapp.com/send?phone=5555996565051"
                             target="_blank">
                  <v-list-item-title>Seja um sócio</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
          <v-col>
            <div class="m-footer-title">
              <div class="m-title">
                <span>
                  <strong>Notícias</strong>
                </span>
              </div>
              <div class="m-border"></div>
            </div>
            <div class="m-footer-link">
              <v-list>
                <v-list-item class="m-link-menu" link to="/noticias/1">
                  <v-list-item-title>Últimas</v-list-item-title>
                </v-list-item>
                <v-list-item class="m-link-menu" link to="/fotos/1">
                  <v-list-item-title>Fotos</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
          <v-col>
            <div class="m-footer-title">
              <div class="m-title">
                <span>
                  <strong>TV União</strong>
                </span>
              </div>
              <div class="m-border"></div>
            </div>
            <div class="m-footer-link">
              <v-list>
                <v-list-item class="m-link-menu" link
                             href="https://www.youtube.com/channel/UC3eIqzjTG5LA0qDHE4zPQig/featured" target="_blank">
                  <v-list-item-title>Inscreva-se</v-list-item-title>
                </v-list-item>
                <v-list-item class="m-link-menu" link to="/videos/1">
                  <v-list-item-title>Vídeos</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
          <v-col>
            <div class="m-footer-title">
              <div class="m-title">
                <span>
                  <strong>Contato</strong>
                </span>
              </div>
              <div class="m-border"></div>
            </div>
            <div class="m-footer-link">
              <v-list>
                <v-list-item class="m-link-menu" link to="/contato">
                  <v-list-item-title>Fale com o União</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="m-footer-body-lg d-none d-md-block d-lg-block d-xl-block">
      <v-container>
        <v-row class="m-align-itens">
          <v-col md="4" sm="4" cols>
            <div class="m-text-footer-body">
              <strong>Endereço de Correspondência</strong> <br>
              Rua Rui Barbosa, 784 - Sala 02, Centro<br>
              98400-000, Frederico Westphalen/RS
            </div>
          </v-col>
          <v-col md="4" sm="4" cols class="align-center">
            <router-link to="/">
              <img class="m-img-logo" src="../assets/logo.svg" alt="União Frederiquense de Futebol">
            </router-link>
          </v-col>
          <v-col md="4" sm="4" cols>
            <div class="m-social-network" v-if="parseData(this.$store.state.site)">
              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.facebook"
                     :href="parseData(this.$store.state.site).social.facebook" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>

              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.instagram"
                     :href="parseData(this.$store.state.site).social.instagram" target="_blank">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>

              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.linkedin"
                     :href="parseData(this.$store.state.site).social.linkedin" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>

              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.twitter"
                     :href="parseData(this.$store.state.site).social.twitter" target="_blank">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>

              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.whatsapp"
                     :href="parseData(this.$store.state.site).social.whatsapp" target="_blank">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="m-footer-body-sm d-block d-md-none d-lg-none d-xl-none">
      <v-container>
        <v-row>
          <v-col cols="12">
            <router-link to="/">
              <img class="m-img-logo" src="../assets/logo.svg" alt="União Frederiquense de Futebol">
            </router-link>
          </v-col>
          <v-col cols="12">
            <div class="m-text-footer-body">
              Estrada Santo Caeran, S/N - B. Faguense<br>
              Frederico Westphalen-RS 98400-000
            </div>
          </v-col>
          <v-col cols="12">
            <div class="m-social-network" v-if="parseData(this.$store.state.site)">
              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.facebook"
                     :href="parseData(this.$store.state.site).social.facebook" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>

              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.instagram"
                     :href="parseData(this.$store.state.site).social.instagram" target="_blank">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>

              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.linkedin"
                     :href="parseData(this.$store.state.site).social.linkedin" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>

              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.twitter"
                     :href="parseData(this.$store.state.site).social.twitter" target="_blank">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>

              <v-btn icon x-large v-if="parseData(this.$store.state.site).social.whatsapp"
                     :href="parseData(this.$store.state.site).social.whatsapp" target="_blank">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="m-copyright" v-if="parseData(this.$store.state.site)">
      © Copyright {{ new Date().getFullYear() }} - {{ parseData(this.$store.state.site).name }}
      <div class="m-politics">
        <router-link to="/politica">Política de Privacidade</router-link>
      </div>

    </div>

  </v-footer>
</template>

<script>
import {parseData} from "../utils";

export default {
  data() {
    return {}
  },
  methods: {
    parseData,
  }
}

</script>

<style lang="scss">
.v-footer {
  background-color: var(--cor-principal) !important;
  color: var(--white) !important;
  display: block !important;

  .m-footer-topo {
    padding: 100px 0 30px 0 !important;

    .m-footer-title {
      margin-bottom: 30px;

      .m-title {
        text-align: left;
        text-transform: uppercase;
        font-size: 18px;
        color: var(--white);
      }

      .m-border {
        margin-top: 3px;
        width: 100px;
        height: 5px;
        background-color: var(--white);
      }
    }
  }

  .m-footer-body-lg {
    background-color: var(--white);
    padding: 15px 0;
    color: var(--cor-principal);
    position: relative;

    .m-align-itens{
      align-items: center;
    }

    .m-text-footer-body {
      font-size: 16px;
      text-align: left;
      line-height: 1.3;
    }

    .m-img-logo {
      position: absolute;
      margin: auto;
      bottom: -35px;
      width: 180px;
      left: calc(50% - 75px);
    }

    .m-social-network {
      text-align: right;

      .v-btn {
        color: var(--cor-principal);

        .v-icon {
          font-size: 30px;
        }
      }

      .m-btn-location-footer {
        text-align: center;
        color: var(--white);
        border-radius: 10px;
        font-size: 14px;
        font-weight: 500;
        background-color: var(--cor-principal);
        letter-spacing: normal;
        padding: 0 10px;

        .v-icon {
          font-size: 18px;
        }
      }
    }
  }

  .m-footer-body-sm {
    text-align: center;
    background-color: var(--white);
    padding: 24px 0;
    font-size: 16px;
    color: var(--cor-principal);

    .m-img-logo {
      width: 180px;
    }

    .m-social-network {

      .v-btn {
        color: var(--cor-principal);

        .v-icon {
          font-size: 30px;
        }
      }
    }
  }

  .m-footer-link {
    .v-list {
      background: transparent;

      .primary--text {
        color: var(--white) !important;
      }

      .v-list-group {
        .v-list-item {
          min-height: 35px;
          width: 100%;
          padding: 0 10px;

          .v-list-item__content {
            padding: 7px 0;
          }

          &::before {
            border-radius: 5px;
          }
        }

        .v-list-group__items {
          .m-link-menu {
            padding: 0 50px;

            .v-list-item__title {
              font-size: 16px;
            }

            &::before {
              border-radius: 5px;
            }
          }
        }
      }

      .m-link-menu {
        min-height: 35px;
        width: 100%;
        padding: 0 10px;

        .v-list-item__title {
          font-size: 16px;
        }

        &::before {
          border-radius: 5px;
        }
      }
    }
  }


  .m-copyright {
    text-align: center;
    padding: 50px 0 10px 0;
    background-color: var(--cor-segundaria);
    color: #ffffffb8;
    font-size: 12px;

    a {
      margin-left: 3px;

      svg {
        transition: all 0.2s ease-in;
        fill: #ffffffb8;
        margin-top: -6px;
      }

      &:hover,
      &:active {
        svg {
          fill: #f9aa70;
        }
      }
    }

    .m-politics{
      a{
        text-decoration: none;
        color: #ffffffb8;
        &:hover,
        &:active {
          color: var(--white);
        }
      }
    }
  }
}

</style>
