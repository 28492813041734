import moment from 'moment';
import 'moment/locale/pt-br'
import 'moment-precise-range-plugin';

function corta_string(str, size) {
    if (str === undefined || str === 'undefined' || str === '' || size === undefined || size === 'undefined' || size === '') {
        return str;
    }

    var shortText = str;
    if (str.length >= size + 3) {
        shortText = str.substring(0, size).concat('...');
    }
    return shortText;
}

function parseData(data) {
    if (!data) return false;
    if (typeof data === 'object') return data;
    if (typeof data === 'string') return JSON.parse(data);
    return false;
}

function convertToUrl(data) {
    return data.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/ +/g, "-")
        .replace(/-{2,}/g, "-");
}

function getCapa(data) {
    const response = data.filter(r => {
        return r.cover === 'S'
    })
    return response[0];
}

function removeTag(data) {
    const response = new DOMParser().parseFromString(data, 'text/html');
    return response.body.textContent || "";
}

function tempoAtualData(data) {
    const ends = moment();
    //const duration = moment.duration(ends.diff(data));
    const diff = moment.preciseDiff(data, ends, true);
    //const diffHuman = moment.preciseDiff(data, ends);

    return diff
}

export {
    corta_string, parseData, convertToUrl, getCapa, removeTag, tempoAtualData
}

