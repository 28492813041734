<template>
  <div class="m-view-presidents">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>Presidentes do
                    <strong>União</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-presidents">
          <v-row v-if="items">
            <v-col md="3" sm="4" cols v-for="(item, i) in items" :key="i">
              <v-card v-if="item.src"
                      class="m-card-presidents"
                      :key="i"
                      flat
                      height="300px"
              >
                <v-img
                    class="white--text align-end"
                    height="300px"
                    :src="item.src">
                  <div class="m-background-card"></div>
                  <v-card-title class="m-group-management d-block">
                    <div class="m-presidents">{{ `Gestão ${item.year}` }}</div>
                    <div class="m-name">{{ item.name }}</div>
                    <div class="m-office">{{ item.office.description }}</div>
                  </v-card-title>
                </v-img>
              </v-card>
              <v-card v-else class="m-card-presidents-not-src" :key="i" flat>
                <v-card-title class="m-group-presidents d-block">
                  <div class="m-presidents">{{ `Gestão ${item.year}` }}</div>
                  <div class="m-name">{{ item.name }}</div>
                  <div class="m-office">Presidente</div>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";

export default {
  name: "Presidentes",
  components: {Topo, Footer},

  data: () => ({
    title: 'Presidentes do União',
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Presidentes do União',
        disabled: true
      },
    ],
    items: [
      {name: 'Edison Cantarelli', year: '2023/2024'},
      {name: 'Vinícius Girardi', year: '2021/2022'},
      {name: 'Edison Cantarelli', year: '2017/2020'},
      {name: 'Vinícius Girardi', year: '2015/2016'},
      {name: 'Edio Salton', year: '2015'},
      {name: 'Celson Oliveira', year: '2010/2014'},

    ]
  }),
  metaInfo() {
    return {
      title: `Presidentes do União | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-presidents {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }


  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-body-presidents {
      .m-card-presidents {
        border-radius: 10px;

        .v-image__image {
          &::after {
            background: linear-gradient(
                    to bottom,
                    transparent 35%,
                    var(--dark) 100%
            );
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
          }
        }

        .m-group-presidents {
          letter-spacing: initial;
          word-break: normal;
          padding: 24px;
          color: var(--grey) !important;

          .m-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 1.2;
            text-transform: uppercase;
            min-height: 20px;
            max-height: 20px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .m-office {
            font-size: 12px;
            line-height: 1.2;
          }

          .m-presidents {
            font-size: 12px;
            line-height: 1.2;
          }
        }

        &::before {
          border-radius: 10px !important;
        }
      }

      .m-card-presidents-not-src {
        border-radius: 10px;
        background-color: var(--faint-gray);

        .m-group-presidents {
          letter-spacing: initial;
          word-break: normal;
          padding: 24px;
          color: var(--grey) !important;

          .m-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 1.2;
            text-transform: uppercase;
            min-height: 40px;
            max-height: 40px;
            max-width: 100%;
          }

          .m-office {
            font-size: 12px;
            line-height: 1.2;
          }

          .m-presidents {
            font-size: 12px;
            line-height: 1.2;
          }
        }
      }

    }
  }
}
</style>
