<template>
  <div class="m-menu">
    <nav class="m-nav-top m-topo-lg d-none d-lg-block d-xl-block">
      <v-app-bar dark flat class="m-nav-top-lg">
        <v-container>
          <v-row>
            <v-col>
              <div class="d-flex align-center">
                <router-link to="/" class="m-link-logo">
                  <img class="m-logo" src="../assets/logo_menu.svg" alt="Logotipo">
                </router-link>
                <v-spacer></v-spacer>
                <div class="link">
                  <v-btn class="ma-1 btn-menu" link depressed to="/">HOME</v-btn>
                  <v-menu
                      class="m-menu-topo"
                      rounded
                      offset-y
                      open-on-hover
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn-menu" v-bind="attrs" v-on="on" link depressed>
                        <v-icon>
                          mdi-arrow
                        </v-icon>
                        FUTEBOL
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-group
                          no-action
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title>Futebol Profissional</v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-list-item class="m-link-menu" link to="/elenco/1">
                          <v-list-item-content>
                            <v-list-item-title>Elenco</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="m-link-menu" link to="/jogos/1">
                          <v-list-item-content>
                            <v-list-item-title>Jogos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                  </v-menu>
                  <v-menu
                      class="m-menu-topo"
                      open-on-hover
                      rounded
                      offset-y
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn-menu" v-bind="attrs" v-on="on" link depressed>
                        <v-icon>
                          mdi-arrow
                        </v-icon>
                        CLUBE
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-group
                          no-action
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title>História</v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-list-item class="m-link-menu" link to="/fundacao">
                          <v-list-item-content>
                            <v-list-item-title>Fundação</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="m-link-menu" link to="/arena">
                          <v-list-item-content>
                            <v-list-item-title>Arena</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="m-link-menu" link to="/simbolos">
                          <v-list-item-content>
                            <v-list-item-title>Nossos Símbolos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="m-link-menu" link to="/hinos">
                          <v-list-item-content>
                            <v-list-item-title>Nosso Hino</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                      <v-list-item class="m-link-menu" link to="/presidentes">
                        <v-list-item-title>Presidentes</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="m-link-menu" link to="/gestao#conselho-administrativo">
                        <v-list-item-title>Conselho Administrativo</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="m-link-menu" link to="/gestao#conselho-deliberativo">
                        <v-list-item-title>Conselho Deliberativo</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="m-link-menu" link to="/gestao#estatuto">
                        <v-list-item-title>Estatuto</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="m-link-menu" link to="/editais">
                        <v-list-item-title>Editais</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn class="ma-1 btn-menu" link depressed to="/noticias/1">NOTÍCIAS</v-btn>
                  <v-btn class="ma-1 btn-menu" link depressed href="/#section-partner">SEJA SÓCIO</v-btn>
                  <v-btn class="ma-1 btn-menu" link depressed to="/contato">CONTATO</v-btn>
                  <v-btn
                      class="ma-1 btn-menu"
                      link
                      icon
                      @click="dialog = true"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>

                <!--<a href="https://api.whatsapp.com/send?phone=555596565051" class="m-link-logo" target="_blank">
                  <img class="m-image-store" src="@/assets/uniao_store.svg" alt="Loja">
                </a>-->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>
      <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          depressed
          small
          class="m-btn-to-top"
          @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </nav>
    <nav class="m-nav-top m-topo-sm d-block d-lg-none d-xl-none">
      <v-app-bar class="m-nav-top-sm" dark flat>
        <v-toolbar-title>
          <router-link to="/" class="m-link-logo">
            <img class="m-logo" src="../assets/logo_menu.svg" alt="Logotipo">
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="btn-menu" icon v-show="back" @click="goTo()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>
    </nav>
    <v-sheet class="overflow-hidden menu-left">
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list-item>
          <img class="logo" src="../assets/logo_sm.svg" alt="Logotipo">
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item link to="/">
            <v-list-item-content>
              <v-list-item-title>
                HOME
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group>
            <template v-slot:activator>
              <v-list-item-title>FUTEBOL</v-list-item-title>
            </template>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Futebol Profissional</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link to="/elenco/1">
                <v-list-item-title>Elenco</v-list-item-title>
              </v-list-item>
              <v-list-item link to="/jogos/1">
                <v-list-item-title>Jogos</v-list-item-title>
              </v-list-item>

            </v-list-group>

          </v-list-group>

          <v-list-group>
            <template v-slot:activator>
              <v-list-item-title>CLUBE</v-list-item-title>
            </template>

            <v-list-group no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>História</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item class="m-link-menu" link to="/fundacao">
                <v-list-item-content>
                  <v-list-item-title>Fundação</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="m-link-menu" link to="/arena">
                <v-list-item-content>
                  <v-list-item-title>Arena</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="m-link-menu" link to="/simbolos">
                <v-list-item-content>
                  <v-list-item-title>Nossos Símbolos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="m-link-menu" link to="/hinos">
                <v-list-item-content>
                  <v-list-item-title>Nosso Hino</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item class="m-link-menu" link to="/presidentes">
              <v-list-item-title>Presidentes</v-list-item-title>
            </v-list-item>
            <v-list-item class="m-link-menu" link to="/gestao#conselho-administrativo">
              <v-list-item-title>Conselho Administrativo</v-list-item-title>
            </v-list-item>
            <v-list-item class="m-link-menu" link to="/gestao#conselho-deliberativo">
              <v-list-item-title>Conselho Deliberativo</v-list-item-title>
            </v-list-item>
            <v-list-item class="m-link-menu" link to="/gestao#estatuto">
              <v-list-item-title>Estatuto</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item link to="/noticias/1">
            <v-list-item-content>
              <v-list-item-title>
                NOTÍCIAS
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link href="https://api.whatsapp.com/send?phone=5555996565051">
            <v-list-item-content>
              <v-list-item-title>
                SEJA SÓCIO
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/contato">
            <v-list-item-content>
              <v-list-item-title>
                CONTATO
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-text-field
              v-model="search"
              :error-messages="searchErrors"
              label="Pesquisar"
              single-line
              solo
              clearable
              required
              @input="$v.search.$touch()"
              @blur="$v.search.$touch()"
              @click:append="searcher()"
              append-icon="mdi-magnify"
          ></v-text-field>

          <a href="https://api.whatsapp.com/send?phone=555596565051" class="m-link-logo" target="_blank">
            <img class="m-image-store" src="@/assets/uniao_store.svg" alt="Loja">
          </a>

        </v-list>
      </v-navigation-drawer>
    </v-sheet>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <v-card class="m-dialog-searcher">
          <v-card-title>
            <span class="text-h5">O que você procura?</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-text-field
                    v-model="search"
                    :error-messages="searchErrors"
                    label="Pesquisar"
                    single-line
                    solo
                    clearable
                    required
                    @input="$v.search.$touch()"
                    @blur="$v.search.$touch()"
                    @click:append="searcher()"
                    append-icon="mdi-magnify"
                ></v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                depressed
                class="m-btn-dialog-close"
                @click="dialog = false"
            >
              Fechar
            </v-btn>
            <v-btn
                depressed
                class="m-btn-dialog-send"
                @click="searcher()"
            >
              Pesquisar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, minLength} from 'vuelidate/lib/validators'
import {parseData} from "../utils";

export default {
  props: ["back"],
  data: () => ({
    drawer: false,
    fab: false,
    dialog: false,
    search: ''
  }),
  mixins: [validationMixin],

  validations: {
    search: {required, minLength: minLength(5)},
  },

  computed: {
    searchErrors() {
      const errors = []
      if (!this.$v.search.$dirty) return errors
      !this.$v.search.minLength && errors.push('O campo deve ter no mínino 3 caracteres.')
      !this.$v.search.required && errors.push('O campo é obrigatório.')
      return errors
    },
  },


  methods: {
    parseData,
    goTo() {
      if (this.back === true) {
        this.$router.go(-1);
      } else {
        this.$router.push(this.back);
      }
      this.$emit("changed");
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    async searcher() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return;
      }
      this.dialog = false;
      this.$router.push({
        path: `/pesquisar/${this.search}`,
      }).catch(err => {
        console.log(err)
      });
      if (this.$route.name === 'Pesquisar') {
        location.reload();
      }
    }
  }
}
</script>

<style lang="scss">

.m-menu {
  position: sticky;
  top: 0;
  z-index: 7;

  .m-nav-top {
    -webkit-box-shadow: 0 8px 11px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 8px 11px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 8px 11px 0 rgb(0 0 0 / 10%);

    .m-nav-top-lg {
      background-color: var(--white) !important;
      height: 120px !important;

      .v-toolbar__content {
        border-bottom: 6px solid var(--cor-principal);
        height: 120px !important;


        .m-link-logo {
          text-decoration: none;
          display: flex;

          .m-logo {
            //width: 100%;
            height: 90px;
          }
        }
      }
      .m-image-store {
        width: 150px;
        margin-left: 15px;
      }
      .btn-menu {
        font-weight: 600;
        letter-spacing: initial;
        text-indent: initial;
        padding: 0 7px !important;
        background-color: transparent !important;
        color: var(--cor-principal) !important;
      }

    }

    .m-nav-top-sm {
      background-color: var(--white) !important;
      height: 90px !important;

      .v-toolbar__content {
        height: 90px !important;
        border-bottom: 6px solid var(--cor-principal);

        .m-link-logo {
          text-decoration: none;
          display: flex;

          .m-logo {
            width: 100%;
            height: 65px;
          }
        }
      }
    }

    .v-app-bar__nav-icon{
      color: var(--cor-principal);
    }

  }

  .v-overlay {
    height: 100vh !important;
  }

  .v-navigation-drawer {
    height: 100vh !important;

    img {
      height: 75px;
      padding: 10px 0;
    }

    .v-list {
      color: var(--grey) !important;

      .primary--text {
        color: var(--grey) !important;
      }
    }
  }

  .m-btn-to-top {
    background-color: var(--cor-principal) !important;
    width: 43px !important;
    height: 43px !important;
    bottom: 66px !important;
    right: 6px!important;
  }
}

@media (min-width: 1264px) {
  .menu {
    .v-sheet {
      .btn-menu {
        padding: 0 16px !important;
      }
    }
  }
}

.m-dialog-searcher {

  .v-card__title {
    color: var(--grey);
  }

  .v-card__actions {
    .m-btn-dialog-close {
      text-align: center;
      color: var(--white);
      height: 45px;
      width: 150px;
      border-radius: 10px;
      font-weight: 600;
      letter-spacing: normal;
      background-color: var(--grey) !important;
    }

    .m-btn-dialog-send {
      text-align: center;
      color: var(--white);
      height: 45px;
      width: 150px;
      border-radius: 10px;
      font-weight: 600;
      letter-spacing: normal;
      background-color: var(--cor-principal) !important;
    }
  }
}

</style>
