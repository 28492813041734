import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Noticias from '../views/Noticias.vue'
import VerNoticia from '../views/VerNoticia.vue'
import Elenco from '../views/Elenco.vue'
import VerElenco from '../views/VerElenco.vue'
import Contato from '../views/Contato.vue'
import Gestao from '../views/Gestao.vue'
import Editais from '../views/Editais.vue'
import Fotos from '../views/Fotos.vue'
import VerFoto from '../views/VerFoto.vue'

import Fundacao from '../views/Fundacao.vue'
import Arena from '../views/Arena.vue'
import Simbolos from '../views/Simbolos.vue'
import Herois from '../views/Herois.vue'


import Videos from '../views/Videos.vue'
import Jogos from '../views/Jogos.vue'
import Hinos from '../views/Hinos.vue'
import Presidentes from '../views/Presidentes.vue'
import Pesquisar from '../views/Pesquisar.vue'
import Politica from '../views/Politica.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/', name: 'Home', component: Home
}, {
    path: '/noticias/:page', name: 'Noticias', component: Noticias
}, {
    path: '/noticia/:id/:title', name: 'Noticia', component: VerNoticia
}, {
    path: '/elenco/:id/:subcategory/:title', name: 'Jogador', component: VerElenco
}, {
    path: '/elenco/:id', name: 'Elenco', component: Elenco
}, {
    path: '/jogos/:id', name: 'Jogos', component: Jogos
},
    {
    path: '/gestao', name: 'Gestao', component: Gestao
},
    {
    path: '/editais', name: 'Editais', component: Editais
},

    {
    path: '/contato', name: 'Contato', component: Contato
}, {
    path: '/fotos/:page', name: 'Fotos', component: Fotos
}, {
    path: '/foto/:id/:title', name: 'Foto', component: VerFoto
}, {
    path: '/videos/:page', name: 'Videos', component: Videos
}, {
    path: '/fundacao', name: 'Fundacao', component: Fundacao
}, {
    path: '/arena', name: 'Arena', component: Arena
}, {
    path: '/simbolos', name: 'Simbolos', component: Simbolos
}, {
    path: '/herois', name: 'Herois', component: Herois
}, {
    path: '/hinos', name: 'Hinos', component: Hinos
}, {
    path: '/presidentes', name: 'Presidentes', component: Presidentes
}, {
    path: '/pesquisar/:search', name: 'Pesquisar', component: Pesquisar
}, {
    path: '/politica', name: 'Politica', component: Politica
},


]

const router = new VueRouter({
    mode: 'history', base: process.env.BASE_URL, routes, scrollBehavior(to, href, from, savedPosition) {
        if (to.hash) {
            return {selector: to.hash}
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0}
        }
    }
})

export default router
