<template>
  <div class="m-home">
    <Topo/>
    <section class="m-section-page section-highlights" v-if="items.highlights.length > 0">
      <v-container>
        <div class="m-body-section">
          <v-row>
            <v-col md="9" cols>
              <v-row>
                <v-col cols v-for="(item, i) in items.mainHighlights" :key="i">
                  <v-card
                      class="m-card-news-main"
                      :key="i"
                      flat
                      :to="`/noticia/${item.id}/${convertToUrl(item.title)}`"
                  >
                    <v-img
                        class="white--text align-end"
                        :src="item.src"
                    >
                      <div class="m-background-card"></div>
                      <v-card-title class="m-group-title-new d-block">
                        <div class="group m-group-date">
                          <v-icon dark>mdi-calendar-month-outline</v-icon>
                          {{ item.date }}
                        </div>
                        <div class="m-title-new">{{ item.title }}</div>
                      </v-card-title>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="3" cols>
              <v-row>
                <v-col cols v-for="(item, i) in items.highlights" :key="i">
                  <v-card
                      class="m-card-news"
                      :key="i"
                      flat
                      :to="`/noticia/${item.id}/${convertToUrl(item.title)}`"
                  >
                    <v-img
                        class="white--text align-end"
                        :src="item.src"
                    >
                      <div class="m-background-card"></div>
                      <v-card-title class="m-group-title-new d-block">
                        <div class="group m-group-date">
                          <v-icon dark>mdi-calendar-month-outline</v-icon>
                          {{ item.date }}
                        </div>
                        <div class="m-title-new">{{ item.title }}</div>
                      </v-card-title>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <!--
    <section class="m-section-page background-highlights d-none d-md-block d-lg-block d-xl-block" v-if="items.mainHighlights">
      <div class="m-highlights-section">
        <router-link :to="`/noticia/${news.id}/${convertToUrl(items.mainHighlights.title)}`" class="m-card-main-news">
          <v-img :src="items.mainHighlights.src">
            <div class="m-background-card"></div>
          </v-img>
        </router-link>
        <div class="m-body-highlights">
          <v-container>
            <v-row>
              <v-col>
                <router-link class="m-group-title-new d-block"
                             :to="`/noticia/${items.mainHighlights.id}/${convertToUrl(items.mainHighlights.title)}`">
                  <div class="group m-group-date">
                    <v-icon dark> mdi-calendar</v-icon>
                    {{ items.mainHighlights.date }}
                  </div>
                  <div class="m-title-new">{{ items.mainHighlights.title }}</div>
                </router-link>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  md="4"
                  sm="6"
                  cols
                  v-for="(item) in items.highlights"
                  :key="item.id"
              >
                <v-card
                    :class="item.id === items.mainHighlights.id ? 'm-card-news active' : 'm-card-news'"
                    flat
                    @click="mainHighlightsAdd(item)"
                    height="300px"
                >

                  <v-img
                      class="white--text align-end"
                      height="300px"
                      :src="item.src"
                  >

                    <div class="m-background-card"></div>
                    <v-card-title class="m-group-title-new d-block">
                      <div class="group m-group-date">
                        <v-icon dark> mdi-calendar</v-icon>
                        {{ item.date }}
                      </div>
                      <div class="m-title-new">{{ item.title }}</div>
                    </v-card-title>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </section>
    <section class="m-section-page background-highlights d-block d-md-none d-lg-none d-xl-none" v-if="items.mainHighlights">
      <div class="m-highlights-section">
        <div class="m-body-highlights-sm">
          <v-container>
            <v-row>
              <v-col
                  md="4"
                  sm="6"
                  cols
                  v-for="(item) in items.highlights"
                  :key="item.id"
              >
                <v-card
                    :class="item.id === items.mainHighlights.id ? 'm-card-news active' : 'm-card-news'"
                    flat
                    height="300px"
                    :to="`/noticia/${item.id}/${convertToUrl(item.title)}`"
                >

                  <v-img
                      class="white--text align-end"
                      height="300px"
                      :src="item.src"
                  >

                    <div class="m-background-card"></div>
                    <v-card-title class="m-group-title-new d-block">
                      <div class="group m-group-date">
                        <v-icon dark> mdi-calendar</v-icon>
                        {{ item.date }}
                      </div>
                      <div class="m-title-new">{{ item.title }}</div>
                    </v-card-title>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </section>-->
    <section class="m-section-page section-games" v-if="items.games">
      <v-container>
        <div class="m-title-section">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span
                  >Próximo
                    <strong>Jogo</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-section">
          <v-row class="m-row">
            <v-col md="5" cols>
              <v-row class="align-content-center">
                <v-col>
                  <div class="m-card-games d-inline-flex">
                    <div class="m-team-1 text-center">
                      <img :src="items.games.team1.arms" :alt="items.games.team1.name" class="m-imagem-arms">
                      <div class="m-name-team" v-html="items.games.team1.name"></div>
                    </div>
                    <div class="m-scoreboard d-inline-flex">
                      <div class="m-scoreboard-result">
                        {{ items.games.team1.scoreboard ? items.games.team1.scoreboard : "" }}
                      </div>
                      <div class="m-scoreboard-x">X</div>
                      <div class="m-scoreboard-result">
                        {{ items.games.team2.scoreboard ? items.games.team2.scoreboard : "" }}
                      </div>
                    </div>
                    <div class="m-team-2 text-center">
                      <img :src="items.games.team2.arms" :alt="items.games.team2.name" class="m-imagem-arms">
                      <div class="m-name-team" v-html="items.games.team2.name"></div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="false">
                <v-col>
                  <div class="m-card-time">
                    <div class="m-title-time">
                      <span v-if="dateIsBefore && items.timeGame.hours < 1 && items.timeGame.minutes < 40">
                        <v-badge
                            color="green"
                            inline
                            content="Ao Vivo"
                        >
                          Tempo da<strong class="ml-1"> partida</strong>
                      </v-badge>
                      </span>
                      <span
                          v-if="dateIsBefore && items.timeGame.hours >= 1 && items.timeGame.minutes > 40">Partida <strong>finalizada</strong></span>
                      <span v-if="!dateIsBefore">Tempo para a <strong>partida</strong></span>
                    </div>
                    <div class="m-time-game d-inline-flex"
                         v-if="dateIsBefore && items.timeGame.hours >= 1 && items.timeGame.minutes > 40">
                      <div class="m-time-day">
                        <div class="m-title-time-day">
                          00
                        </div>
                        <div class="m-subtitle-time-day">DIAS</div>
                      </div>
                      <div class="m-time-space">:</div>
                      <div class="m-time-day">
                        <div class="m-title-time-day">
                          00
                        </div>
                        <div class="m-subtitle-time-day">HORAS</div>
                      </div>
                      <div class="m-time-space">:</div>
                      <div class="m-time-day">
                        <div class="m-title-time-day">
                          00
                        </div>
                        <div class="m-subtitle-time-day">MINUTOS</div>
                      </div>
                      <div class="m-time-space">:</div>
                      <div class="m-time-day">
                        <div class="m-title-time-day">00
                        </div>
                        <div class="m-subtitle-time-day">SEGUNDOS</div>
                      </div>
                    </div>
                    <div class="m-time-game d-inline-flex"
                         v-if="dateIsBefore && items.timeGame.hours <= 1 && items.timeGame.minutes > 40">
                      <div class="m-time-day">
                        <div class="m-title-time-day">
                          {{ items.timeGame.days > 9 ? items.games.days : `0${items.timeGame.days}` }}
                        </div>
                        <div class="m-subtitle-time-day">DIAS</div>
                      </div>
                      <div class="m-time-space">:</div>
                      <div class="m-time-day">
                        <div class="m-title-time-day">
                          {{ items.timeGame.hours > 9 ? items.timeGame.hours : `0${items.timeGame.hours}` }}
                        </div>
                        <div class="m-subtitle-time-day">HORAS</div>
                      </div>
                      <div class="m-time-space">:</div>
                      <div class="m-time-day">
                        <div class="m-title-time-day">{{
                            items.timeGame.minutes > 9 ? items.timeGame.minutes : `0${items.timeGame.minutes}`
                          }}
                        </div>
                        <div class="m-subtitle-time-day">MINUTOS</div>
                      </div>
                      <div class="m-time-space">:</div>
                      <div class="m-time-day">
                        <div class="m-title-time-day">{{
                            items.timeGame.seconds > 9 ? items.timeGame.seconds : `0${items.timeGame.seconds}`
                          }}
                        </div>
                        <div class="m-subtitle-time-day">SEGUNDOS</div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="3" cols>
              <div class="m-games-info">
                <div class="m-info-name">
                  <div class="m-title">CAMPEONATO</div>
                  <div class="m-name"
                       v-html="`${items.games.championship.name} ${items.games.championship.season}`"></div>
                </div>
                <div class="m-info-date">
                  <div class="m-title">DATA</div>
                  <!--<div class="m-day" v-html="items.games.weekday"></div>-->
                  <div class="m-date"
                       v-html="`${items.games.weekday} - ${items.games.date} às ${items.games.time}`"></div>
                </div>
                <div class="m-info-local">
                  <div class="m-title">LOCAL</div>
                  <div class="m-stadium" v-html="items.games.stadium"></div>
                  <!--<div class="m-city" v-html="items.games.local"></div>-->
                </div>
              </div>
            </v-col>
            <v-col md="4" cols>
              <v-card flat class="m-card-classification" v-if="items.classification">
                <v-card-text>
                  <div class="text-center"><strong>{{ items.games.group }}</strong></div>
                  <v-simple-table class="m-table-classification" dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Classificação
                        </th>
                        <th class="text-left">
                          PG
                        </th>
                        <th class="text-left">
                          JG
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in items.classification" :key="item.id">
                        <template v-if="item.position <= 6">
                          <td class="m-table-info">
                            <div class="d-inline-flex">
                              <div class="m-info-position">{{ `${item.position}º` }}</div>
                              <img class="m-image-arms" :src="item.team.arms" :alt="item.team.name"/>
                              <div class="m-info-name">{{ item.team.name }}</div>
                            </div>
                          </td>
                          <td>{{ item.pg }}</td>
                          <td>{{ item.j }}</td>
                        </template>
                        <template v-if="item.id === 280112 && item.position > 6">
                          <td class="m-table-info">
                            <div class="d-inline-flex">
                              <div class="m-info-position">{{ `${item.position}º` }}</div>
                              <img class="m-image-arms" :src="item.team.arms" :alt="item.team.name"/>
                              <div class="m-info-name">{{ item.team.name }}</div>
                            </div>
                          </td>
                          <td>{{ item.pg }}</td>
                          <td>{{ item.j }}</td>
                        </template>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="m-section-page background-partner" ref="section-partner" id="section-partner">
      <v-container>
        <div class="m-body-section">
          <v-row class="align-center align-content-center" no-gutters>
            <v-col md="8" offset-md="2">
              <img class="m-image-timaco" src=".././assets/image_socio.svg"/>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col md="6" offset-md="3">
              <v-row>
                <v-col md="6" sm="6" cols>
                  <v-card class="m-card-partner" flat>
                    <v-card-text>
                      <img class="m-img-icone" src="@/assets/001_Prancheta 1.svg" alt="Icone">
                      <div class="m-title">
                        <strong>Descontos</strong> em
                        Produtos Oficiais
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="6" sm="6" cols>
                  <v-card class="m-card-partner" flat>
                    <v-card-text>
                      <img class="m-img-icone" src="@/assets/002_Prancheta 1.svg" alt="Icone">
                      <div class="m-title">
                        <strong>Vantagem</strong> no
                        Comércio Local
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="6" sm="6" cols>
                  <v-card class="m-card-partner" flat>
                    <v-card-text>
                      <img class="m-img-icone" src="@/assets/003_Prancheta 1.svg" alt="Icone">
                      <div class="m-title">
                        <strong>Ajuda</strong> no
                        Crescimento do Clube
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="6" sm="6" cols>
                  <v-card class="m-card-partner" flat>
                    <v-card-text>
                      <img class="m-img-icone" src="@/assets/004_Prancheta 1.svg" alt="Icone">
                      <div class="m-title">
                        <strong>Acesso garantido</strong>
                        em todos os jogos na Arena
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col md="6" offset-md="3" class="text-center">
              <v-btn depressed href="https://uniaofrederiquense.areadosocio.com.br/#/entrada" target="_blank"
                     class="mt-8 m-btn-partner"> SEJA SÓCIO
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="m-section-page background-lion" v-if="false">
      <v-btn class="m-btn-comprar" dark depressed href="https://api.whatsapp.com/send?phone=555596565051"
             target="_blank">
        <div class="m-btn-info">
          <div class="m-span-1">Clique aqui</div>
          <div class="m-span-2">e adquira os produtos do <br>União Frederiquense</div>
        </div>
      </v-btn>
    </section>
    <section class="m-section-page" v-if="items.news.length > 0">
      <v-container>
        <div class="m-title-section">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <router-link class="m-title" to="/noticias">
                  <span
                  >Últimas
                    <strong>Notícias</strong>
                  </span>
                </router-link>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-section section-news">
          <v-row>
            <v-col md="4" sm="6" cols v-for="(item, i) in items.news" :key="i">
              <v-card
                  class="m-card-news"
                  :key="i"
                  flat
                  :to="`/noticia/${item.id}/${convertToUrl(item.title)}`"
                  height="300px"
              >
                <v-img
                    class="white--text align-end"
                    height="300px"
                    :src="item.src"
                >
                  <div class="m-background-card"></div>
                  <v-card-title class="m-group-title-new d-block">
                    <div class="group m-group-date">
                      <v-icon dark>mdi-calendar-month-outline</v-icon>
                      {{ item.date }}
                    </div>
                    <div class="m-title-new">{{ item.title }}</div>
                  </v-card-title>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn depressed class="m-btn-last-news" to="/noticias/1"> Mais Notícias</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="m-section-page background-video" v-if="items.videos.length > 0 && items.mainVideos.length > 0">
      <v-container>
        <div class="m-title-section">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span
                  >TV
                    <strong>União</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
            <v-col>
              <v-btn class="m-btn-video float-md-right float-sm-none" depressed
                     href="https://www.youtube.com/channel/UC3eIqzjTG5LA0qDHE4zPQig/featured" target="_blank">
                <v-icon class="mr-2">mdi-youtube</v-icon>
                <span>INSCREVER-SE</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-section">
          <v-row>
            <v-col md="9" cols>
              <div class="images-wrapper">
                <v-row>
                  <v-col cols v-for="(item, i) in items.mainVideos" :key="i">
                    <v-card
                        class="m-card-video"
                        :key="i"
                        flat
                        @click="index = i"
                    >
                      <v-img
                          class="white--text align-end image"
                          :src="item.miniature"
                      >
                        <div class="m-background-card"></div>
                        <v-card-title class="m-group-title-video d-block">
                          <div class="group m-group-date">
                            <v-icon dark>mdi-calendar-month-outline</v-icon>
                            {{ item.date }}
                          </div>
                          <div class="m-title-video">{{ item.title }}</div>
                        </v-card-title>
                      </v-img>
                    </v-card>
                  </v-col>
                  <CoolLightBox
                      :items="items.mainVideos"
                      :index="index"
                      @close="index = null">
                  </CoolLightBox>
                </v-row>
              </div>
            </v-col>
            <v-col md="3" cols>
              <div class="m-vertical-videos">
                <div class="images-wrapper">
                  <v-row>
                    <v-col cols v-for="(item, i) in items.videos" :key="i">
                      <v-card
                          class="m-card-video"
                          :key="i"
                          flat
                          height="200px"
                          @click="indexVertical = i"
                      >
                        <v-img
                            class="white--text align-end image"
                            height="200px"
                            :src="item.miniature"
                        >
                          <div class="m-background-card"></div>
                          <v-card-title class="m-group-title-video d-block">
                            <div class="group m-group-date">
                              <v-icon dark>mdi-calendar-month-outline</v-icon>
                              {{ item.date }}
                            </div>
                            <div class="m-title-video">{{ item.title }}</div>
                          </v-card-title>
                        </v-img>
                      </v-card>
                    </v-col>
                    <CoolLightBox
                        :items="items.videos"
                        :index="indexVertical"
                        @close="indexVertical = null">
                    </CoolLightBox>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn depressed class="m-btn-video" to="/videos/1"> Mais Vídeos</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="m-section-page section-players" v-if="items.players.length > 0">
      <v-container>
        <div class="m-title-section">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>Elenco</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="m-body-section">
          <v-row>
            <v-col>
              <v-tabs v-model="tab">
                <v-tab
                    v-for="item in items.categoryPlayers"
                    :key="item.id"
                    @click="filterPlayers(item.id)">
                  {{ item.description }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in items.categoryPlayers" :key="item.tab">
                  <v-slide-group
                      v-model="modelPlayers"
                      center-active
                      show-arrows
                  >
                    <v-slide-item
                        v-for="(play, i) in items.filterPlayers"
                        :key="i"
                        v-slot="{ active, toggle }"
                    >
                      <v-card
                          class="ma-4"
                          width="300"
                          flat
                          :class="i % 2 === 0 ? 'm-card-players-par' : 'm-card-players-impar'"
                          @click="toggle"
                          :to="`/elenco/${play.id}/${play.subcategory.id}/${convertToUrl(play.name)}`"
                      >
                        <v-img :src="play.src"></v-img>

                        <v-card-text style="position: relative">
                          <v-btn v-if="Number(play.number) > 0 && play.number"
                                 class="m-btn-number"
                                 dark
                                 absolute
                                 top
                                 right
                                 fab
                                 small
                                 v-html="play.number"
                          >
                          </v-btn>
                          <div class="m-player-name" v-html="play.name"></div>
                          <div class="m-player-profession" v-html="play.profession.description"></div>
                        </v-card-text>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="m-section-page background-sponsors" v-if="items.sponsors.length > 0">
      <v-container>
        <div class="m-title-section">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>Patrocinadores</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-section">
          <v-row>
            <v-col md="3" sm="4" cols v-for="(item, i) in items.sponsors" :key="i">
              <a :href="item.link" target="_blank">
                <v-img
                    class="m-image-sponsors"
                    height="115px"
                    :src="item.src"
                    :alt="item.name"
                ></v-img>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <Footer/>
  </div>
</template>

<script>
import {parseData, convertToUrl, tempoAtualData} from "../utils";
import moment from 'moment';
import 'moment/locale/pt-br'
import 'moment-precise-range-plugin';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Topo from "../components/Topo";
import Footer from "../components/Footer";

export default {
  name: "Home",

  components: {
    Topo,
    Footer,
    CoolLightBox
  },
  data() {
    return {
      momentInstance: '',
      dateIsBefore: false,
      items: {
        news: [],
        highlights: [],
        mainHighlights: {},
        sponsors: [],
        videos: [],
        mainVideos: [],
        games: null,
        timeGame: [],
        classification: [],
        players: [],
        filterPlayers: [],
        categoryPlayers: [],
      },
      options: {
        currentPage: 0,
        direction: 'vertical',
        infinite: 4,
        slidesToScroll: 4,
        loop: true
      },
      tab: null,
      modelPlayers: null,
      index: null,
      indexVertical: null,
    };
  },
  async created() {
    this.highlights();
    this.news();
    this.sponsors();
    this.videos();
    this.players();
    //await this.update();
    await this.games();
  },

  methods: {
    mainHighlightsAdd(data) {
      this.items.mainHighlights = data;
    },
    highlights() {
      this.loading = true;
      this.Api()
          .post("/home_highlights", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items.highlights = response.data.news.slice(1);
            //this.items.mainHighlights = this.items.highlights.splice(0, 1);
            this.items.mainHighlights = [response.data.news[0]];

            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    news() {
      this.loading = true;
      this.Api()
          .post("/home_latest_news", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items.news = response.data.news.slice(4);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    sponsors() {
      this.loading = true;
      this.Api()
          .post("/home_sponsors", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items.sponsors = response.data.sponsors;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    videos() {
      this.loading = true;
      this.Api()
          .post("/home_videos", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items.videos = response.data.videos;
            this.items.mainVideos = this.items.videos.splice(0, 1);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    players() {
      this.loading = true;
      this.Api()
          .post("/home_players", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items.players = response.data.players;
            this.items.filterPlayers = response.data.players.filter(r => {
              return r.category.id === 1
            })
            response.data.players.map(data => {

              const index = this.items.categoryPlayers.findIndex((i) => {
                return i.id === data.category.id;
              })
              if (index < 0) {
                this.items.categoryPlayers.push(data.category);
              }
            });

            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    filterPlayers(data) {

      this.items.filterPlayers = this.items.players.filter(r => {
        return r.category.id === data
      })
    },
    async games() {
      this.loading = true;
      await this.Api()
          .post("/home_games", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items.games = response.data.games;
            this.items.classification = response.data.classification;
            this.items.timeGame = tempoAtualData(response.data.games.day)
            this.loading = false;
            this.dateIsBefore = moment(this.items.games.day).isBefore(moment());
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    async update() {
      this.loading = true;
      await this.Api()
          .post("/api_games", {
            id: parseData(this.$store.state.site).id,
          }).catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },

    parseData,
    convertToUrl,
  },
  mounted() {
    setInterval(() => {
      if (this.items.games) {
        this.items.timeGame = tempoAtualData(this.items.games.day)
      } else {
        this.items.timeGame = tempoAtualData(new Date())
      }
    }, 1000)
  },
  metaInfo() {
    return {
      title: `${
          parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ""
      }`,
    };
  },
};
</script>

<style lang="scss">
.m-home {
  .m-section-page {
    padding: 30px 0;

    .m-title-section {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 18px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }
  }

  .section-highlights {

    .m-body-section {
      .m-card-news {
        border-radius: 10px;

        height: 250px;

        .v-image{
          height: 250px;
        }

        .v-image__image {
          &::after {
            background: linear-gradient(
                    to bottom,
                    transparent 0%,
                    var(--dark) 100%
            );
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
          }
        }

        .m-group-title-new {
          letter-spacing: initial;
          word-break: normal;
          padding: 24px;

          .m-group-date {
            font-size: 12px;
            margin-bottom: 7px;

            .v-icon {
              font-size: 16px;
            }
          }

          .m-title-new {
            font-weight: 500;
            font-size: 16px;
            line-height: 1.4;
            text-transform: uppercase;
            min-height: 48px;
            max-height: 48px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        &::before {
          border-radius: 10px !important;
        }
      }

      .m-card-news-main {
        @extend .m-card-news;

        height: 350px;

        .v-image{
          height: 350px;
        }

        .m-group-title-new {

          .m-title-new {
            font-size: 20px;
            min-height: 60px;
            max-height: 60px;
          }
        }
      }
    }
  }

  .background-highlights {
    padding: 0;

    .m-highlights-section {
      .m-card-main-news {
        .v-image {
          height: 50vh;

          .v-image__image {
            background-position: center !important;

            &::after {
              background: linear-gradient(
                      to bottom,
                      transparent 0%,
                      var(--dark) 100%
              );
              bottom: 0;
              content: "";
              height: 100%;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
            }
          }
        }
      }

      .m-body-highlights {
        margin-top: -55%;
        position: relative;

        .m-group-title-new {
          letter-spacing: initial;
          word-break: normal;
          color: var(--white);
          text-decoration: none;

          .m-group-date {
            font-size: 16px;
            margin-bottom: 5px;
            transition: all 0.5s;

            .v-icon {
              font-size: 18px;
            }
          }

          .m-title-new {
            font-weight: 600;
            font-size: 20px;
            line-height: 1.4;
            min-height: 85px;
            text-transform: uppercase;
            transition: all 0.5s;
          }
        }

        .m-card-news {
          border-radius: 10px;

          .v-image__image {
            &::after {
              background: linear-gradient(
                      to bottom,
                      transparent 0%,
                      var(--dark) 100%
              );
              bottom: 0;
              content: "";
              height: 100%;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
            }
          }

          .m-group-title-new {
            letter-spacing: initial;
            word-break: normal;
            padding: 24px;

            .m-group-date {
              font-size: 12px;
              margin-bottom: 7px;

              .v-icon {
                font-size: 16px;
              }
            }

            .m-title-new {
              font-weight: 500;
              font-size: 16px;
              line-height: 1.4;
              text-transform: uppercase;
              min-height: 48px;
              max-height: 48px;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          &::before {
            border-radius: 10px !important;
          }
        }

        .active {
          .m-group-title-new {

            .m-group-date {
              display: none !important;
            }

            .m-title-new {
              display: none !important;
            }
          }
        }
      }

      .m-body-highlights-sm {
        margin-top: 30px;
        position: relative;

        .m-group-title-new {
          letter-spacing: initial;
          word-break: normal;
          color: var(--white);
          text-decoration: none;

          .m-group-date {
            font-size: 16px;
            margin-bottom: 5px;
            transition: all 0.5s;

            .v-icon {
              font-size: 18px;
            }
          }

          .m-title-new {
            font-weight: 600;
            font-size: 20px;
            line-height: 1.4;
            min-height: 85px;
            text-transform: uppercase;
            transition: all 0.5s;
          }
        }

        .m-card-news {
          border-radius: 10px;

          .v-image__image {
            &::after {
              background: linear-gradient(
                      to bottom,
                      transparent 0%,
                      var(--dark) 100%
              );
              bottom: 0;
              content: "";
              height: 100%;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
            }
          }

          .m-group-title-new {
            letter-spacing: initial;
            word-break: normal;
            padding: 24px;

            .m-group-date {
              font-size: 12px;
              margin-bottom: 7px;

              .v-icon {
                font-size: 16px;
              }
            }

            .m-title-new {
              font-weight: 500;
              font-size: 16px;
              line-height: 1.4;
              text-transform: uppercase;
              min-height: 48px;
              max-height: 48px;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          &::before {
            border-radius: 10px !important;
          }
        }
      }
    }
  }

  .m-row {
    align-items: center;
  }

  .section-games {
    background-color: #f5f5f5c2;

    .m-body-section {
      .m-card-time {
        text-align: center;
        color: var(--grey);

        .m-title-time {
          margin-bottom: 15px;
        }

        .m-time-game {
          align-items: center;;

          .m-time-day {
            text-align: center;
            padding: 5px;
            min-width: 60px;
            border-radius: 10px;
            -webkit-box-shadow: 0 6px 20px -5px rgb(0 0 0 / 18%);
            box-shadow: 0 6px 20px -5px rgb(0 0 0 / 18%);

            .m-title-time-day {
              font-size: 20px;
              font-weight: 600;
              line-height: 1;
            }

            .m-subtitle-time-day {
              font-size: 9px;
              line-height: 1;
            }
          }

          .m-time-space {
            font-size: 16px;
            font-weight: bold;
            padding: 5px;
          }
        }
      }

      .m-card-games {
        align-items: center;
        color: var(--grey);
        width: 100%;

        .m-name-team {
          margin-top: 15px;
          color: var(--grey);
          line-height: 1.2;
          font-size: 14px;
        }

        .m-team-1 {
          width: 35%;

          .m-imagem-arms {
            height: 80px;
          }
        }

        .m-team-2 {
          width: 35%;

          .m-imagem-arms {
            height: 80px;
          }
        }

        .m-scoreboard {
          width: 30%;
          padding: 5px;

          .m-scoreboard-x {
            text-align: center;
            margin: 0 5px;
            min-width: 10px;
            color: var(--disabled);
            font-size: 35px;
          }

          .m-scoreboard-result {
            text-align: center;
            min-width: 10px;
            font-weight: 600;
            font-size: 35px;
          }
        }
      }

      .m-games-info {
        .m-title {
          font-size: 16px;
          font-weight: 600;
          color: var(--grey);
        }

        .m-info-name {
          line-height: 1.2;
          margin-bottom: 15px;
        }

        .m-info-date {
          line-height: 1.2;
          margin-bottom: 15px;
        }

        .m-info-local {
          line-height: 1.2;
        }
      }


      .m-card-classification {
        border-radius: 10px;
        -webkit-box-shadow: 0 6px 20px -5px rgb(0 0 0 / 18%) !important;
        box-shadow: 0 6px 20px -5px rgb(0 0 0 / 18%) !important;

        .m-table-classification {
          .m-table-info {
            padding: 0 5px;

            .m-image-arms {
              width: 23px;
              vertical-align: middle;
            }

            .m-info-name {
              margin-left: 5px;
              font-size: 13px;
            }

            .m-info-position {
              font-weight: bold;
              margin-right: 6px;
              width: 25px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .section-news {
    .m-card-news {
      border-radius: 10px;

      .v-image__image {
        &::after {
          background: linear-gradient(
                  to bottom,
                  transparent 0%,
                  var(--dark) 100%
          );
          bottom: 0;
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
        }
      }

      .m-group-title-new {
        letter-spacing: initial;
        word-break: normal;
        padding: 24px;

        .m-group-date {
          font-size: 12px;
          margin-bottom: 7px;

          .v-icon {
            font-size: 16px;
          }
        }

        .m-title-new {
          font-weight: 500;
          font-size: 16px;
          line-height: 1.4;
          text-transform: uppercase;
          min-height: 48px;
          max-height: 48px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      &::before {
        border-radius: 10px !important;
      }
    }

    .m-btn-last-news {
      text-align: center;
      color: var(--white);
      height: 50px;
      width: 250px;
      border-radius: 10px;
      font-weight: 600;
      background-color: var(--cor-principal);
    }
  }

  .background-lion {
    background-image: url(../assets/banner_01.jpg);
    background-position: center;
    background-size: cover;
    height: 800px;
    position: relative;

    .m-btn-comprar {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 16px !important;
      height: auto !important;
      color: var(--white);
      border-radius: 10px;
      font-weight: 600;
      background-color: var(--cor-principal);

      .m-btn-info {
        .m-span-1 {
          font-size: 24px;
        }

        .m-span-2 {
          font-size: 12px;
        }
      }
    }
  }

  .background-video {
    background-image: url(../assets/image_home.jpg);
    background-position: center;
    background-size: cover;

    .m-btn-video {
      color: var(--white);
      height: 50px;
      width: 250px;
      border-radius: 10px;
      font-weight: 600;
      background-color: var(--cor-principal);
    }

    .m-title-section {
      .m-body-title {
        .m-title {
          color: var(--white);
        }

        .m-border {
          background-color: var(--white);
        }
      }
    }

    .m-body-section {
      .m-card-video {
        border-radius: 10px;
        height: 400px;

        .v-image {
          height: 400px;

          .v-image__image {
            &::after {
              background: linear-gradient(
                      to bottom,
                      transparent 25%,
                      var(--dark) 100%
              );
              bottom: 0;
              content: "";
              height: 100%;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
            }
          }
        }


        .m-group-title-video {
          letter-spacing: initial;
          word-break: normal;
          padding: 24px;

          .m-group-date {
            font-size: 12px;
            margin-bottom: 7px;
            line-height: 0;

            .v-icon {
              font-size: 16px;
            }
          }

          .m-title-video {
            font-weight: 500;
            font-size: 16px;
            line-height: 1.4;
            text-transform: uppercase;
            min-height: 48px;
            max-height: 48px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        &::before {
          border-radius: 10px !important;
        }
      }

      .m-vertical-videos {
        .m-card-video {
          border-radius: 10px;

          .v-image__image {
            &::after {
              background: linear-gradient(
                      to bottom,
                      transparent 20%,
                      var(--dark) 100%
              );
              bottom: 0;
              content: "";
              height: 100%;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
            }
          }

          .m-group-title-video {
            letter-spacing: initial;
            word-break: normal;
            padding: 24px;

            .m-group-date {
              font-size: 12px;
              margin-bottom: 7px;
              line-height: 0;

              .v-icon {
                font-size: 16px;
              }
            }

            .m-title-video {
              font-weight: 500;
              font-size: 16px;
              line-height: 1.4;
              text-transform: uppercase;
              min-height: 48px;
              max-height: 48px;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          &::before {
            border-radius: 10px !important;
          }
        }
      }
    }
  }

  .background-partner {
    background-image: url(../assets/partner.jpg);
    background-position: center;
    background-size: cover;

    .m-body-section {

      .m-card-partner {
        background-color: #ffffff45;
        border-radius: 10px;
        text-align: center;
        border: 4px solid var(--cor-segundaria);

        .v-card__text {
          padding: 30px;
          line-height: 1.2;

          .m-title {
            color: var(--cor-segundaria) !important;
            font-size: 20px;
            min-height: 65px;
          }

          .m-img-icone {
            width: 125px;
            height: auto;
          }

          .m-icon {
            color: var(--cor-segundaria) !important;
            font-size: 75px;
            margin-bottom: 15px;
          }
        }
      }

      .m-btn-partner {
        margin: auto;
        color: var(--white);
        height: 50px;
        width: 250px;
        border-radius: 10px;
        font-weight: 600;
        background-color: var(--cor-principal);
      }

      .m-image-timaco {
        width: 100%;
      }
    }
  }

  .section-players {

    .v-tabs {
      .primary--text {
        color: var(--cor-principal) !important;
      }
    }

    .m-card-players-par {

      background-color: var(--cor-principal);
      border-radius: 10px;

      .m-btn-number {
        font-size: 18px;
        font-weight: 600;
        color: var(--cor-principal);
        background-color: var(--white);
      }

      .m-player-name {
        color: var(--white);
        font-size: 16px;
      }

      .m-player-profession {
        color: var(--white);
        font-size: 14px;
      }
    }

    .m-card-players-impar {
      @extend .m-card-players-par;
      background-color: var(--cor-segundaria);
    }
  }

  .background-sponsors {

    .m-image-sponsors {
      border-radius: 10px;

      .v-image__image--cover {
        background-size: contain;
      }
    }
  }
}

@media (min-width: 768px) {
  .m-home {
    .m-section-page {
      padding: 100px 0;

      .m-title-section {
        .m-body-title {
          .m-title {
            font-size: 24px;
          }
        }
      }
    }

    .m-new {
      .m-highlights-section-new {
        .m-body-highlights-new {
          .m-card-news-active {
            height: 710px;

            .v-image {
              height: 710px;
            }

            .m-group-title-new {
              .m-title-new {
                font-size: 24px;
                min-height: 80px;
                max-height: 80px;
              }
            }
          }

          .m-card-news {

          }
        }
      }
    }

    .background-highlights {
      .m-highlights-section {
        .m-card-main-news {
          .v-image {
            height: 90vh;
          }
        }

        .m-body-highlights {
          margin-top: -15%;

          .m-group-title-new {
            .m-title-new {
              font-size: 30px;
            }
          }
        }
      }
    }

    .background-lion {
      .m-body-section {

        .m-card-lion-info {
          .m-title-lion {
            font-size: 60px;
          }

          .m-img-logo {
            height: 200px;
          }

          .m-btn-partner {
            width: 50%;
          }
        }
      }
    }
  }
}

@media (min-width: 525px) {
  .m-home {
    .section-highlights {

      .m-body-section {
        .m-card-news {
          border-radius: 10px;

          height: 210px;

          .v-image{
            height: 210px;
          }


          .v-image__image {
            &::after {
              background: linear-gradient(
                      to bottom,
                      transparent 0%,
                      var(--dark) 100%
              );
              bottom: 0;
              content: "";
              height: 100%;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
            }
          }

          .m-group-title-new {
            letter-spacing: initial;
            word-break: normal;
            padding: 24px;

            .m-group-date {
              font-size: 12px;
              margin-bottom: 7px;

              .v-icon {
                font-size: 16px;
              }
            }

            .m-title-new {
              font-weight: 500;
              font-size: 16px;
              line-height: 1.4;
              text-transform: uppercase;
              min-height: 48px;
              max-height: 48px;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          &::before {
            border-radius: 10px !important;
          }
        }

        .m-card-news-main {
          @extend .m-card-news;

          height: 680px;

          .v-image{
            height: 680px;
          }


          .m-group-title-new {

            .m-title-new {
              font-size: 20px;
              min-height: 60px;
              max-height: 60px;
            }
          }
        }
      }
    }

    .section-games {
      .m-body-section {
        .m-card-games {
          .m-name-team {
            margin-top: 15px;
            font-size: 14px;
          }

          .m-team-1 {
            .m-imagem-arms {
              height: 150px;
            }
          }

          .m-team-2 {
            .m-imagem-arms {
              height: 150px;
            }
          }

          .m-scoreboard {
            padding: 10px;

            .m-scoreboard-x {
              min-width: 40px;
              font-size: 55px;
            }

            .m-scoreboard-result {
              min-width: 40px;
              font-size: 55px;
            }
          }
        }

        .m-card-time {
          .m-time-game {
            .m-time-day {
              padding: 10px;
              min-width: 75px;

              .m-title-time-day {
                font-size: 24px;
              }
            }

            .m-time-space {
              padding: 10px;
            }
          }
        }
      }
    }

    .background-video {
      .m-body-section {
        .m-card-video {
          height: 648px;

          .v-image {
            height: 648px;

          }
        }
      }
    }
  }
}

</style>