<template>
  <v-app>
    <router-view/>
    <v-alert
        class="m-popup-privacy-policy" v-model="alert" dark >
      <div class="m-text-popup">
        <p>Utilizamos cookies para garantir que oferecemos a melhor experiência durante a sua navegação. Ao continuar
          navegando no site, assumiremos que você concorda.</p>
        <v-btn class="btn-accept-policy" depressed @click.stop="setAcceptPolicy()">
          Aceitar
        </v-btn>
      </div>
    </v-alert>
  </v-app>
</template>

<script>
import {parseData} from "./utils";

export default {
  name: 'App',
  data() {
    return {
      alert: false,
    };
  },
  async created() {
    Number(this.$store.state.politics) === 1 ? this.alert = false : this.alert = true;
    if (!parseData(this.$store.state.site)) {
      await this.initialize()
    }
  },
  methods: {
    parseData,
    async initialize() {
      await this.Api().post('/home_site').then(response => {
        this.$store.commit('setSite', response.data);
      }).catch(error => {
        console.log(error)
      });
    },
    async setAcceptPolicy() {
      this.$store.commit('setPolitica', 1);
      this.alert = false;
    },
  },
  metaInfo() {
    return {
      meta: [
        {name: 'google-site-verification', content: parseData(this.$store.state.site).google_site_verification},
        {name: 'description', content: parseData(this.$store.state.site).description},
        {name: 'keywords', content: parseData(this.$store.state.site).keywords},
        {name: 'application-name', content: parseData(this.$store.state.site).title},
        {name: 'author', content: parseData(this.$store.state.site).author}
      ]
    }
  }
};
</script>
<style lang="scss">
#app {
  .m-popup-privacy-policy {
    background-color: var(--white);
    color: var(--grey);
    text-align: left;
    position: fixed;
    margin-bottom: 0;
    bottom: 16px;
    left: 16px;
    width: 75%;
    z-index: 999;
    line-height: 1.2;
    -webkit-box-shadow: 0 4px 11px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 4px 11px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 4px 11px 0 rgb(0 0 0 / 10%);
    a {
      text-decoration: none;
      color: var(--yellow);
    }

    .btn-accept-policy {
      color: var(--white);
      background-color: var(--cor-segundaria);
      text-transform: none;
      letter-spacing: 0;
    }
  }
}

.v-menu__content {
  .v-list {

    .primary--text {
      color: var(--cor-principal) !important;
    }

    .v-list-group {
      .m-link-menu {
        padding-left: 40px
      }
    }

    .m-link-menu {
      width: 100%;

      .v-list-item__title {
        font-size: 16px;
      }
    }
  }
}

.v-breadcrumbs {
  padding: 0;
}

.v-btn {
  letter-spacing: initial;
}

.text-not-data {
  text-align: center;

  .v-icon {
    font-size: 56px;
    color: var(--disabled);
  }

  .subtitle-not-data {
    color: var(--disabled);
    font-size: 16px;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>