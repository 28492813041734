<template>
  <div class="m-page-news">
    <Topo :back="true" />
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular
          :size="50"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-container v-else>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>{{ title }}</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col md="4" sm="6" cols v-for="(item, i) in visiblePages" :key="i">
            <v-card
              class="m-card-news"
              :key="i"
              flat
              :to="`/noticia/${item.id}/${convertToUrl(item.title)}`"
              height="300px"
            >
              <v-img
                class="white--text align-end"
                height="300px"
                :src="
                  item.image
                    ? getCapa(item.image).img
                    : '../assets/img_padrao.png'
                "
              >
                <div class="m-background-card"></div>
                <v-card-title class="m-group-title-new d-block">
                  <div class="group m-group-date">
                    <v-icon dark> mdi-calendar </v-icon>
                    {{ item.date }}
                  </div>
                  <div class="m-title-new">{{ item.title }}</div>
                </v-card-title>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="items.length > 0">
          <v-col>
            <v-pagination
              v-if="items.length > perPage"
              v-model="page"
              :length="Math.ceil(items.length / perPage)"
            ></v-pagination>
          </v-col>
        </v-row>
        <v-row v-if="items.length === 0">
          <v-col>
            <div class="text-not-data">
              <v-icon>mdi-information-outline</v-icon>
              <div class="title-not-data"><strong>OOPS!</strong></div>
              <div class="subtitle-not-data">Não há dados cadastrado</div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import { parseData, convertToUrl, getCapa } from "../utils";

export default {
  name: "Notícias",

  components: { Topo, Footer },

  data: () => ({
    title: "Últimas Notícias",
    loading: true,
    items: [],
    page: 1,
    perPage: 21,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Últimas Notícias",
        disabled: true,
      },
    ],
  }),

  created() {
    this.initialize();
    this.page = this.$route.params.page ? parseInt(this.$route.params.page) : 1;
  },

  methods: {
    parseData,
    convertToUrl,
    getCapa,
    async initialize() {
      this.loading = true;
      await this.Api()
        .post("/news", {
          id: parseData(this.$store.state.site).id,
        })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    updateUrl() {
      this.$router.push({ path: `/noticias/${this.page}` }).catch(() => {});
    },
  },

  computed: {
    visiblePages() {
      this.updateUrl();
      return this.items.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },

  metaInfo() {
    return {
      title: `Notícias | ${
        parseData(this.$store.state.site)
          ? parseData(this.$store.state.site).name
          : ""
      }`,
    };
  },
};
</script>

<style lang="scss">
.m-page-news {
  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-card-news {
      border-radius: 10px;

      .v-image__image {
        &::after {
          background: linear-gradient(
            to bottom,
            transparent 0%,
            var(--dark) 100%
          );
          bottom: 0;
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
        }
      }

      .m-group-title-new {
        letter-spacing: initial;
        word-break: normal;
        padding: 24px;

        .m-group-date {
          font-size: 12px;
          margin-bottom: 7px;
          line-height: 0;
          .v-icon {
            font-size: 16px;
          }
        }

        .m-title-new {
          font-weight: 500;
          font-size: 16px;
          line-height: 1.4;
          text-transform: uppercase;
          min-height: 48px;
          max-height: 48px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      &::before {
        border-radius: 10px !important;
      }
    }
  }
}
</style>
