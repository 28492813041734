<template>
  <div class="m-view-anthem">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>Nosso
                    <strong>Hino</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-anthem">
          <v-row>
            <v-col>
              <div>Conheça o hino que ecoa em cada partida na torcida do leão.</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-expansion-panels
                  v-model="panel"
                  multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header><strong>Hino Oficial do União Frederiquense de Futebol</strong>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <p>União Frederiquense<br>
                          Glorioso, time guerreiro<br>
                          Orgulho de nossa terra<br>
                          Gaúcho e brasileiro</p>

                        <p>Dentro e fora da Colina<br>
                          Heróico filho do Barril<br>
                          Ser campeão é tua sina<br>
                          Em todos os cantos do Brasil</p>

                        <p>União Frederiquense<br>
                          Glorioso, time guerreiro<br>
                          Orgulho de nossa terra<br>
                          Gaúcho e brasileiro</p>

                        <p>Pra frente, sempre pra frente<br>
                          Postura de vencedor<br>
                          Não teme nenhum oponente<br>
                          Tens na raça o teu maior valor</p>

                        <p>União Frederiquense<br>
                          Glorioso, time guerreiro<br>
                          Orgulho de nossa terra<br>
                          Gaúcho e brasileiro</p>

                        <p>Verde, vermelho e branco, a aliança<br>
                          Nas tuas cores, nossa paixão<br>
                          Tuas conquistas, nossa esperança<br>
                          Honra, respeito e tradição</p>

                        <p>União Frederiquense<br>
                          Glorioso, time guerreiro<br>
                          Orgulho de nossa terra<br>
                          Gaúcho e brasileiro</p>

                        <p>Letra: <strong>Edison Luis Ferraz</strong></p>
                        <p>Música e arranjo: <strong>Sandro Vieira/Conexão Studios</strong></p>

                      </v-col>
                      <v-col>
                        <youtube :video-id="videoId"></youtube>
                        <div class="m-card-audio mt-8">
                          <vuetify-audio :file="audio.src" flat downloadable color="#C0333D"></vuetify-audio>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";
import VuetifyAudio from "vuetify-audio";

export default {
  name: "Hinos",
  components: {Topo, Footer, VuetifyAudio},

  data: () => ({
    title: 'Nosso Hino',
    panel: [0],
    audio: {src: 'https://uniaofw.com.br/dashboard/json/arquivos/audios/Hino_oficial.mp3'},
    videoId: 'cbEZJu3CXWc',
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Nosso Hino',
        disabled: true
      },
    ],
  }),

  metaInfo() {
    return {
      title: `Nosso Hino | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-anthem {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }


  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-body-anthem {


      .v-expansion-panels {
        .v-expansion-panel {
          color: var(--grey);
        }
      }

      .m-card-audio {
        .primary {
          background-color: var(--cor-principal) !important;
          border-color: var(--cor-principal) !important;
        }
      }
    }
  }
}
</style>
