<template>
  <div class="m-page-game">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular
            :size="50"
            indeterminate
        ></v-progress-circular>
      </div>
      <v-container v-else>
        <div class="m-title-view">
          <v-row>
            <v-col md="10" sm="8" cols="12">
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>{{ title }}</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
            <v-col md="2" sm="4" cols="12">
              <v-select
                  solo
                  v-model="ano"
                  :items="anos"
                  label="Ano"
                  item-text="ano"
                  item-value="ano"
                  small-chips
                  @change="initialize"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-game">
          <v-row>
            <v-col>
              <v-tabs v-model="tab" class="m-tab-championship">
                <v-tab v-for="item in items.phases" :key="Number(item.id)">
                  {{ item.name }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" class="m-tabs-items-championship">
                <v-tab-item v-for="item in items.phases" :key="Number(item.id)">
                  <v-row>
                    <v-col cols>
                      <div class="m-name-championship">
                        {{ `${items.championship.name} - ${items.championship.season}` }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="8" sm="6" cols>
                      <div v-for="(group, index) in item.groups" :key="index">
                        <div v-if="item.groups.length > 1" class="text-center mt-41">{{ group.description }}</div>
                        <v-simple-table class="m-table-classification">
                          <template v-slot:default>
                            <thead>
                            <tr>
                              <th class="text-left">Possição</th>
                              <th class="text-left">PG</th>
                              <th class="text-left">J</th>
                              <th class="text-left">V</th>
                              <th class="text-left">E</th>
                              <th class="text-left">D</th>
                              <th class="text-left">GP</th>
                              <th class="text-left">GC</th>
                              <th class="text-left">SG</th>
                              <th class="text-left">%</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item, index) in group.classification"
                                :key="index"
                            >
                              <td class="m-table-info">
                                <div class="d-inline-flex">
                                  <div :class="`m-info-position ${item.classified}`">{{ `${item.position}º` }}</div>
                                  <img class="m-image-arms" :src="item.team.arms" :alt="item.team.name"/>
                                  <div class="m-info-name">{{ item.team.name }}</div>
                                </div>
                              </td>
                              <td>{{ item.pg }}</td>
                              <td>{{ item.j }}</td>
                              <td>{{ item.v }}</td>
                              <td>{{ item.e }}</td>
                              <td>{{ item.d }}</td>
                              <td>{{ item.gp }}</td>
                              <td>{{ item.gc }}</td>
                              <td>{{ item.sg }}</td>
                              <td>{{ item.ap }}</td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                    </v-col>
                    <v-col :md="item.groups ? '4':'12'" :sm="item.groups ? '4':'12'" cols>
                      <v-tabs v-model="tabRound" class="m-tab-games">
                        <v-tab v-for="(data, i) in item.game" :key="i">
                          {{ `Rodada ${i+1 > 9 ? i+1 : 0 + i+1}` }}
                        </v-tab>
                      </v-tabs>

                      <v-tabs-items v-model="tabRound" class="m-tabs-items-championship">
                        <v-tab-item v-for="data in item.game" :key="Number(data.id)">
                          <div class="m-card-games" v-for="(game, i) in data.games" :key="i">
                            <div class="m-date">{{ `${game.date} às ${game.time}` }}</div>
                            <div class="conteudo-escudos">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="mandante" v-bind="attrs" v-on="on">
                                    <img
                                        :src="`${game.team1.arms}`"
                                        :title="game.team1.name" height="50" width="50">
                                    <div class="m-name-team">{{ game.team1.initials }}</div>
                                  </div>
                                </template>
                                <span>{{ game.team1.name }}</span>
                              </v-tooltip>
                              <div class="contra">
                                {{ game.team1.scoreboard }} X {{ game.team2.scoreboard }} <br><br>
                              </div>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="visitante" v-bind="attrs" v-on="on">
                                    <img
                                        :src="`${game.team2.arms}`"
                                        :title="game.team2.name" height="50" width="50">
                                    <div class="m-name-team">{{ game.team2.initials }}</div>
                                  </div>
                                </template>
                                <span>{{ game.team2.name }}</span>
                              </v-tooltip>
                            </div>
                            <div class="m-location">{{ game.stadium }}</div>
                          </div>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>

        </div>
        <v-row v-if="items.length === 0">
          <v-col>
            <div class="text-not-data">
              <v-icon>mdi-information-outline</v-icon>
              <div class="title-not-data"><strong>OOPS!</strong></div>
              <div class="subtitle-not-data">Não há dados cadastrado</div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData, convertToUrl, getCapa} from "../utils";

export default {
  name: "Jogos",

  components: {Topo, Footer},

  data: () => ({
    title: "Jogos",
    loading: true,
    items: [],
    tab: null,
    tabRound: 0,
    anos: [{ano: 2024, id: 59216}, {ano: 2023, id: 49491}, {ano: 2022, id: 77}],
    ano: 2024,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Jogos",
        disabled: true,
      },
    ],
  }),

  created() {
    this.initialize();
  },

  methods: {
    parseData,
    convertToUrl,
    getCapa,
    async initialize() {
      this.tab = null;
      this.loading = true;

      const buscar = this.anos.filter(data => data.ano === this.ano);

      await this.Api()
          .post("/classification", {
            id: parseInt(buscar[0].id),
            category: parseInt(this.$route.params.id),
            year: parseInt(buscar[0].ano)
          })
          .then((response) => {
            this.items = response.data;
            this.tabRound = Number(response.data.phases[0].current_round);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
  },

  metaInfo() {
    return {
      title: `Jogos | ${
          parseData(this.$store.state.site)
              ? parseData(this.$store.state.site).name
              : ""
      }`,
    };
  },
};
</script>

<style lang="scss">
.m-page-game {
  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-body-game {
      color: var(--grey);

      .primary--text {
        color: var(--cor-principal) !important;
        caret-color: var(--cor-principal) !important;
      }

      .m-tab-championship {
        margin-bottom: 30px;
      }

      .m-tab-games {
        margin-bottom: 10px;
      }

      .m-tabs-items-championship {

        .m-card-games {
          text-align: center;
          background-color: var(--faint-gray);
          margin-bottom: 10px;
          border-radius: 10px;
          padding: 10px;

          .m-location {
            font-size: 14px;
          }

          .m-date {
            font-size: 14px;
          }
        }

        .conteudo-escudos {
          width: 100%;
          display: inline-flex;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: bold;

          .m-name-team {
            font-weight: normal;
            font-size: 12px;
            line-height: 1.3;
          }

          .mandante {
            width: 40%;
            float: left;
            text-align: center;
          }

          .contra {
            width: 30%;
            text-transform: uppercase;
            text-align: center;
            padding: 18px 0 2px;
          }

          .visitante {
            width: 40%;
            float: right;
            text-align: center;
          }
        }

        .m-name-championship {
          font-weight: 600;
          font-size: 18px;
        }

        .m-table-classification {
          .m-table-info {
            .m-image-arms {
              width: 23px;
              vertical-align: middle;
            }

            .classified {
              color: green;
            }

            .relegated {
              color: red;
            }

            .kept {
              color: var(--grey);
            }

            .m-info-name {
              margin-left: 6px;
            }

            .m-info-position {
              text-align: center;
              font-weight: bold;
              margin-right: 6px;
              width: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
