<template>
  <div class="m-view-symbols">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>Nossos
                    <strong>Símbolos</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-symbols">
          <v-row>
            <v-col md="6" sm="6" cols>
              <v-row class="m-align-items">
                <v-col md="6" sm="6" cols>
                  <v-img src="../assets/logo.svg"></v-img>
                </v-col>
                <v-col md="6" sm="6" cols>
                  <div>Faça o download do escudo <strong>União Frederiquense de Futebol</strong></div>
                  <v-btn depressed class="m-btn-download mt-3" href="/logotipo.zip" download target="_blank">
                    <v-icon>mdi-download</v-icon>
                    Download
                  </v-btn>
                </v-col>
              </v-row>

            </v-col>
            <v-col md="6" sm="6" cols>
              <v-row class="m-align-items">
                <v-col md="6" sm="6" cols>
                  <v-img src="../assets/mascote.jpg"></v-img>
                </v-col>

                <v-col md="6" sm="6" cols>
                  <div>Faça o download do mascote do <strong>União Frederiquense de Futebol</strong></div>
                  <v-btn depressed class="m-btn-download mt-3" href="/mascote.zip" download target="_blank">
                    <v-icon>mdi-download</v-icon>
                    Download
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";

export default {
  name: "Simbolos",
  components: {Topo, Footer},

  data: () => ({
    title: 'Nossos Símbolos',
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Nossos Símbolos',
        disabled: true
      },
    ],
  }),

  metaInfo() {
    return {
      title: `Nossos Símbolos | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-symbols {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }


  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-body-symbols {
      color: var(--grey);
      line-height: 1.3;

      .m-align-items {
        align-items: center;
      }

      .v-image__image--cover {
        background-size: contain;
      }

      .m-btn-download {
        text-align: center;
        color: var(--white);
        height: 50px;
        border-radius: 10px;
        font-weight: 600;
        background-color: var(--cor-principal);
      }
    }
  }
}
</style>
