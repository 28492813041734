<template>
  <div class="m-view-foundation">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>{{ title }}</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-fundation">
          <v-row>
            <v-col>
              <p class="text-justify">Fundado em 3 de agosto de 2010, o União Frederiquense de Futebol é o primeiro
                clube profissional da
                história
                de Frederico Westphalen.</p>
              <p class="text-justify">O clube "nasceu" com a finalidade de eliminar rivalidades locais, visto que
                dificilmente seria possível
                ingressar profissionalmente com qualquer um dos dois principais clubes amadores do município, Ipiranga e
                Itapagé. Sendo assim, pessoas ligadas aos dois clubes - e também a outros amadores do município -
                optaram
                por
                criar uma nova via, o União Frederiquense. O Ipiranga segue como um clube social, enquanto que o Itapagé
                atualmente possui ativo somente o futebol de categoria amadora de veteranos.</p>
              <p class="text-justify">As cores do União Frederiquense foram escolhidas justamente para acabar com
                qualquer resquício de
                rivalidade
                local. Unem-se o verde do Ipiranga, o branco (neutro) e o vermelho do Itapagé.</p>
              <p class="text-justify">Inicialmente, o União-FW sediava seus jogos no Vermelhão da Colina, estádio locado
                junto ao Itapagé, com
                capacidade para 4,5 mil pessoas. Em 2017, o clube iniciou as atividades na Arena União Frederiquense e
                desde
                então, passa por constantes obras para qualificar a infraestrutura do clube, com previsão de ter
                capacidade
                máxima de 3,5 mil torcedores.</p>
              <p class="text-justify">O mascote oficial do União Frederiquense é o Leão e o Hino Oficial do clube tem
                por título "Heróico filho
                do
                Barril", ambos escolhidos em concurso aberto aos torcedores. A atual diretoria do clube tem como
                presidente
                o
                advogado e empresário, Vinícius Girardi.</p>
              <p class="text-justify">União Frederiquense foi filiado na Federação Gaúcha de Futebol em setembro de 2010
                e disputou a Divisão de
                Acesso do Gauchão de 2011 a 2014. Em 04 de junho de 2014 o clube chegou à sua primeira grande conquista,
                quando obteve uma das três vagas à elite do futebol gaúcho - o Gauchão -, competição que disputou em
                2015.
                Em
                2021, em campanha histórica, o União Frederiquense conquistou o título da Divisão de Acesso, com 19
                jogos
                invictos. </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3" sm="6" cols>
              <v-card flat class="m-card-info" to="/arena">
                <v-card-text>
                  <div class="m-title">Arena</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="6" cols>
              <v-card flat class="m-card-info" to="/simbolos">
                <v-card-text>
                  <div class="m-title">Símbolos</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="6" cols>
              <v-card flat class="m-card-info" to="/gestao">
                <v-card-text>
                  <div class="m-title">Gestão</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3" sm="6" cols>
              <v-card flat class="m-card-info" to="/hinos">
                <v-card-text>
                  <div class="m-title">Nosso Hino</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";

export default {
  name: "Fundacao",
  components: {Topo, Footer},

  data: () => ({
    title: 'Fundação',
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Fundação',
        disabled: true
      },
    ],
  }),

  metaInfo() {
    return {
      title: `Fundação | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-foundation {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }


  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-body-fundation {
      color: var(--grey);
      line-height: 1.3;

      .m-card-info {
        text-align: center;
        border-radius: 10px;
        background-color: var(--faint-gray);
        transition: all 0.5s;

        .m-title {
          font-weight: 600;
          text-transform: uppercase;
          color: var(--grey);
          transition: all 0.5s;
        }

        &:hover, &:focus, &:active {
          .m-title {
            color: var(--white);
          }

          background-color: var(--cor-principal);
        }
      }
    }
  }
}
</style>
