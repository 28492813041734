<template>
  <div class="m-page-cast">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular
            :size="50"
            indeterminate
        ></v-progress-circular>
      </div>
      <v-container v-else>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>{{ title }}</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols>
            <div>Conheça os detalhes de nossos <strong>Jogadores Profissionais</strong> e a <strong>Equipe Técnica.</strong></div>
          </v-col>
        </v-row>
        <div class="m-boby-cast" v-for="(item, i) in items" :key="i">
          <div class="m-title-view mt-9">
            <v-row>
              <v-col>
                <div class="m-body-title">
                  <div class="m-title">
                  <span>
                    <strong>{{ item.title }}</strong>
                  </span>
                  </div>
                  <div class="m-border"></div>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col md="3" sm="4" cols="6" v-for="(play, index) in item.players" :key="index">
              <v-card
                  width="300"
                  flat
                  :class="index % 2 === 0 ? 'm-card-players-par' : 'm-card-players-impar'"
                  :to="`/elenco/${play.id}/${play.subcategory.id}/${convertToUrl(play.name)}`"
              >
                <v-img :src="play.src"></v-img>
                <v-card-text style="position: relative">
                  <v-btn v-if="Number(play.number) > 0 && play.number"
                      class="m-btn-number"
                      dark
                      absolute
                      top
                      right
                      fab
                      small
                      v-html="play.number"
                  >
                  </v-btn>
                  <div class="m-player-name" v-html="play.name"></div>
                  <div class="m-player-profession" v-html="play.profession.description"></div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="items.length === 0">
          <v-col>
            <div class="text-not-data">
              <v-icon>mdi-information-outline</v-icon>
              <div class="title-not-data"><strong>OOPS!</strong></div>
              <div class="subtitle-not-data">Não há dados cadastrado</div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData, convertToUrl, getCapa} from "../utils";

export default {
  name: "Elenco",

  components: {Topo, Footer},

  data: () => ({
    title: "Elenco",
    loading: true,
    items: [],
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Elenco",
        disabled: true,
      },
    ],
  }),

  created() {
    this.initialize();
  },

  methods: {
    parseData,
    convertToUrl,
    getCapa,
    async initialize() {
      this.loading = true;
      await this.Api()
          .post("/cast", {
            id: parseData(this.$store.state.site).id,
            category: parseInt(this.$route.params.id)
          })
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
  },

  metaInfo() {
    return {
      title: `Elenco | ${
          parseData(this.$store.state.site)
              ? parseData(this.$store.state.site).name
              : ""
      }`,
    };
  },
};
</script>

<style lang="scss">
.m-page-cast {
  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;
    color: var(--grey);

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-card-players-par {

      background-color: var(--cor-principal);
      border-radius: 10px;

      .m-btn-number {
        font-size: 18px;
        font-weight: 600;
        color: var(--cor-principal);
        background-color: var(--white);
      }

      .m-player-name {
        color: var(--white);
        font-size: 16px;
      }

      .m-player-profession {
        color: var(--white);
        font-size: 14px;
      }
    }

    .m-card-players-impar {
      @extend .m-card-players-par;
      background-color: var(--cor-segundaria);
    }
  }
}
</style>
