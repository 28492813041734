import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    site: sessionStorage.getItem('site') ? sessionStorage.getItem('site') : sessionStorage.setItem('site', false),
    politics: localStorage.getItem('politics') ? localStorage.getItem('politics') : 0
  },
  mutations: {
    setSite(state, site) {
      state.site = site
      sessionStorage.setItem('site', JSON.stringify(site))
    },
    setPolitica(state, politica) {
      state.politica = politica
      localStorage.setItem('politics', state.politica)
    },

  },
  actions: {},
  modules: {}
})
