<template>
  <div class="m-view-contact">
    <Topo :back="true"/>

    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>

    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>{{ title }}</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col>
            <div class="m-location">
              <strong> Arena do União</strong><br>
              Estrada Santo Caeran, S/N - B. Faguense<br>
              Frederico Westphalen-RS 98400-000<br>
            </div>
            <div class="m-location mt-5 mb-5">
              <strong>Endereço de Correspondência</strong><br>
              Rua Rui Barbosa, 784 - Sala 02, Centro<br>
              98400-000, Frederico Westphalen-RS<br>
            </div>
            <div class="m-location mt-5 mb-5">
              <a href="tel:5537442333">(55) 3744-2333</a><br>
              <a href="tel:55996565051">(55) 9 9656-5051</a><br>
            </div>
            <div class="m-location mt-5 mb-5">
              <a href="mailto:imprensa.uniaofw@gmail.com">imprensa.uniaofw@gmail.com</a><br>
            </div>
            <div class="m-social-network" v-if="parseData(this.$store.state.site)">
              <v-btn icon v-if="parseData(this.$store.state.site).social.facebook"
                     :href="parseData(this.$store.state.site).social.facebook" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>

              <v-btn icon v-if="parseData(this.$store.state.site).social.instagram" :href="parseData(this.$store.state.site).social.instagram" target="_blank">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>

              <v-btn icon v-if="parseData(this.$store.state.site).social.linkedin" :href="parseData(this.$store.state.site).social.linkedin" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>

              <v-btn icon v-if="parseData(this.$store.state.site).social.twitter" :href="parseData(this.$store.state.site).social.twitter" target="_blank">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>

              <v-btn icon v-if="parseData(this.$store.state.site).social.whatsapp" :href="parseData(this.$store.state.site).social.whatsapp" target="_blank">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <form class="m-form-contact">
          <v-row>
            <v-col md="6" cols>
              <v-text-field
                  v-model="subject"
                  :error-messages="subjectErrors"
                  label="Assunto"
                  single-line
                  solo
                  flat
                  clearable
                  required
                  @input="$v.subject.$touch()"
                  @blur="$v.subject.$touch()"
              ></v-text-field>
              <v-text-field
                  v-model="name"
                  :error-messages="nameErrors"
                  label="Nome"
                  single-line
                  solo
                  flat
                  clearable
                  required
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
              ></v-text-field>
              <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  label="E-mail"
                  single-line
                  solo
                  flat
                  clearable
                  required
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
              ></v-text-field>
              <v-text-field
                  v-model="phone"
                  :error-messages="phoneErrors"
                  label="Telefone"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  single-line
                  solo
                  flat
                  clearable
                  required
                  @input="$v.phone.$touch()"
                  @blur="$v.phone.$touch()"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols>
              <v-textarea
                  v-model="message"
                  :error-messages="messageErrors"
                  label="Mensagem"
                  single-line
                  solo
                  flat
                  clearable
                  required
                  @input="$v.message.$touch()"
                  @blur="$v.message.$touch()"
              ></v-textarea>
              <div class="d-flex float-right">
                <v-btn class="m-btn-submit mr-5" :disabled="loading" :loading="loading" depressed @click="submit()">
                  Enviar
                </v-btn>
                <v-btn class="m-btn-reset" depressed @click="reset_form()">
                  Limpar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </form>
        <div class="m-title-view m-title-arena">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>Como chegar na
                    <strong>Arena do União</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>


    <gmaps-map class="m-mapa" :options="mapOptions">
      <gmapsMarker v-for="(item, i) in maps" :key="i" :options="item.options"></gmapsMarker>
    </gmaps-map>
    <Footer/>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {validationMixin} from 'vuelidate'
import {required, minLength, email} from 'vuelidate/lib/validators'
import {mask} from "vue-the-mask";
import {parseData} from "../utils";
import {load} from 'recaptcha-v3'
import {gmapsMap, gmapsMarker} from 'x5-gmaps'

export default {
  name: "Contato",
  components: {Topo, Footer, gmapsMap, gmapsMarker},
  directives: {mask},

  data: () => ({
    title: 'Contato',
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    model: null,
    loading: false,
    snackbar: {
      show: false,
      text: "",
      timeout: 7000,
    },
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Contato',
        disabled: true
      },
    ],
    mapOptions: {
      center: {lat: -27.3639477, lng: -53.4248185},
      zoom: 15,
    },
    maps: [
      {options: {position: {lat: -27.3639477, lng: -53.4248185}}},
    ],
  }),

  mixins: [validationMixin],

  validations: {
    name: {required, minLength: minLength(5)},
    phone: {required, minLength: minLength(14)},
    email: {required, email},
    subject: {required, minLength: minLength(5)},
    message: {required, minLength: minLength(14)},

  },

  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.minLength && errors.push('O nome deve ter no mínino 150 caracteres.')
      !this.$v.name.required && errors.push('O nome é obrigatório.')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Deve ser um e-mail válido.')
      !this.$v.email.required && errors.push('O e-mail é obrigatório.')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      !this.$v.phone.minLength && errors.push('O telefone deve ter no mínino 8 caracteres.')
      !this.$v.phone.required && errors.push('O telefone é obrigatório.')
      return errors
    },
    subjectErrors() {
      const errors = []
      if (!this.$v.subject.$dirty) return errors
      !this.$v.subject.minLength && errors.push('O assunto deve ter no mínino 5 caracteres.')
      !this.$v.subject.required && errors.push('O assunto é obrigatório.')
      return errors
    },
    messageErrors() {
      const errors = []
      if (!this.$v.message.$dirty) return errors
      !this.$v.message.minLength && errors.push('A mensagem deve ter no mínino 14 caracteres.')
      !this.$v.message.required && errors.push('A mensagem é obrigatório.')
      return errors
    },
  },

  methods: {
    parseData,
    async submit() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return;
      }

      this.loading = true;

      const recaptcha = await load(parseData(this.$store.state.site).recaptcha_site_key, {
        useRecaptchaNet: true,
        autoHideBadge: true
      });
      const token = await recaptcha.execute('form');

      setTimeout(() => {
        const data = {
          'name': this.name,
          'phone': this.phone,
          'email': this.email,
          'subject': this.subject,
          'message': this.message,
          'g-recaptcha-response': token,
        };
        this.Api().post('/json/submit_contact', data).then(response => {
          if (response.data.status === "success") {
            this.loading = false;
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.reset_form();
          } else {
            this.snackbar.show = true;
            this.snackbar.text = response.data.text;
            this.loading = false;
            this.reset_form();
          }

        }).catch(error => {
          this.snackbar.show = true;
          this.snackbar.text = error;
          this.loading = false;
          this.reset_form();
        });

      }, 3000);
    },

    reset_form() {
      this.$v.$reset()
      this.name = '';
      this.email = '';
      this.phone = '';
      this.subject = '';
      this.message = '';
    }
  },

  metaInfo() {
    return {
      title: `Contato | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-contact {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-location{
      color: var(--grey);
      a{
        text-decoration: none;
        color: var(--grey);
      }
    }

    .m-form-contact {
      .v-input__slot {
        background: var(--faint-gray) !important;
      }

      .v-textarea {
        textarea {
          height: 270px;
        }
      }

      .m-btn-submit {
        background-color: var(--cor-principal);
        color: var(--white);
        height: 50px;
        width: 150px;
        letter-spacing: initial;
        border-radius: 10px;
      }

      .m-btn-reset {
        @extend .m-btn-submit;
        background-color: var(--grey);
      }
    }
  }

  .m-title-arena{
    margin-top: 50px;
    margin-bottom: 0!important;
  }

  .m-mapa {
    height: 450px;
  }
}
</style>
