import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import pt from 'vuetify/es5/locale/pt';
Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    themes: {
        light: {
            primary: '#CF3034',
            secondary: '#009B54',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107'
        },
    },
    lang: {
        locales: {pt},
        current: 'pt',
    }
});
