<template>
  <div class="m-view-heroes">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>{{ title }}</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";

export default {
  name: "Herois",
  components: {Topo, Footer},

  data: () => ({
    title: 'Herois',
    items: [],
    loading: true,
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Herois',
        disabled: true
      },
    ],
  }),
  created() {
    //this.initialize();
  },
  methods: {
    parseData,
    async initialize() {
      this.loading = true;
      await this.Api()
          .post("/management", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
  },

  metaInfo() {
    return {
      title: `Herois | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-heroes {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }


  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }
  }
}
</style>
