<template>
  <div class="m-view-notices">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular
            :size="50"
            indeterminate
        ></v-progress-circular>
      </div>
      <div v-else>
        <div class="m-notices-info">
          <v-container>
            <div class="m-title-view">
              <v-row>
                <v-col>
                  <div class="m-body-title">
                    <div class="m-title">
                  <span>EDITAIS DO
                    <strong>UNIÃO</strong>
                  </span>
                    </div>
                    <div class="m-border"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="m-body-notices">
              <v-row>
                <v-col md="4" sm="6" cols v-for="(item, i) in items" :key="i">
                  <v-card flat class="m-card-notices">
                    <v-card-text>
                      <div class="m-title-notices">{{ item.title }}</div>
                      <v-btn depressed class="m-btn-download" :href="item.file.download" download target="_blank">
                        <v-icon>mdi-download</v-icon>
                        Download
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";

export default {
  name: "Gestao",
  components: {Topo, Footer},

  data: () => ({
    title: 'Editais do União',
    items: [],
    loading: true,
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Editais do União',
        disabled: true
      },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    parseData,
    async initialize() {
      this.loading = true;
      await this.Api()
          .post("/notices", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items = response.data.notices;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
  },

  metaInfo() {
    return {
      title: `Editais do União | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-notices {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;
    color: var(--grey);

    .m-title-view {
      padding-top: 50px;
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-notices-info {
      .m-card-notices {
        background-color: var(--faint-gray);
        .m-title-notices {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 18px;
          font-weight: 600;
          min-height: 45px;
          text-overflow: ellipsis;
          white-space: normal;
          word-wrap: break-word;
          margin-bottom: 15px;
        }
      }

      .m-text-info {
        text-align: justify;
        line-height: 1.3;
        font-size: 16px;
      }
    }

    .m-btn-download {
      text-align: center;
      color: var(--white);
      height: 50px;
      border-radius: 10px;
      font-weight: 600;
      background-color: var(--cor-principal);
    }
  }
}
</style>
