<template>
  <div class="m-page-ver-resultados">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>

    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular :size="50" indeterminate></v-progress-circular>
      </div>
      <v-container v-else>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>{{ title }}</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="m-subtitle-view">Veja os resultado da pesquisa por: <strong>{{this.$route.params.search}}</strong></div>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="items.length > 0">
          <v-list two-line class="result-list">
            <v-list-item-group>
              <template v-for="(item, index) in items">
                <v-list-item :key="item.index" :href="item.url" target="_blank">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.descrication"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon v-if="active" color="grey lighten-1">
                        mdi-chevron-right
                      </v-icon>
                      <v-icon v-if="!active" color="grey lighten-1">
                        mdi-chevron-right
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-row>
        <v-row v-if="items.length === 0">
          <v-col>
            <div class="text-not-data">
              <v-icon>mdi-information-outline</v-icon>
              <div class="title-not-data"><strong>OOPS!</strong></div>
              <div class="subtitle-not-data">Não foi encontrado dados</div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData, corta_string} from "../utils.js";
import {load} from "recaptcha-v3";

export default {
  name: "Pesquisar",

  components: {Topo, Footer},

  data: () => ({
    title: 'Resultados',
    loading: true,
    items: [],
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Resultados',
        disabled: true,
      },
    ],
  }),

  async created() {
    await this.initialize();
  },

  methods: {
    parseData, corta_string,
    async initialize() {
      this.loading = true;
      const recaptcha = await load(parseData(this.$store.state.site).recaptcha_site_key, {
        useRecaptchaNet: true,
        autoHideBadge: true
      });
      const token = await recaptcha.execute('form');


      this.loading = true;
      const data = {'search': this.$route.params.search,  'g-recaptcha-response': token};
      await this.Api().post('/search', data).then(response => {
        this.items = response.data;
        this.loading = false;
      }).catch(error => {
        console.log(error)
        this.loading = false;
      });
    },
  },

  metaInfo() {
    return {
      title: this.items.title ? 'Pesquisar | ' + this.items.title + ' | ' + parseData(this.$store.state.site).name : 'Pesquisar',
    }
  }
}
</script>

<style lang="scss">
.m-page-ver-resultados {
  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
      .m-subtitle-view{
        color: var(--grey);
      }
    }

    .result-list {
      border-radius: 10px;
      margin-top: 30px;
      width: 100%;
    }
  }

}
</style>
