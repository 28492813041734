<template>
  <div class="m-view-politics">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>Política de
                    <strong>Privacidade</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-arena">
          <v-row>
            <v-col>
              <h4>Termos de Uso</h4>
              <p class="text-justify">
                O site do União Frederiquense tem como objetivo ser um portal institucional e de comunicação.
              </p>
              <p class="text-justify">
                Os comentários, compartilhamentos e opiniões expressas sobre os conteúdos são de responsabilidade dos
                autores, tanto na esfera civil quanto penal.
              </p>
              <p class="text-justify">
                Todos os conteúdos publicados são de propriedade do União Frederiquense e devem ser compartilhados desde
                que se observe os créditos.
              </p>
              <h4>Termos de Uso</h4>
              <p class="text-justify">
                O torcedor reconhece e aceita que o site do União Frederiquense utiliza cookies para acompanhar,
                personalizar a armazenar informações a fim de oferecer um serviço mais personalizado de acordo com as
                características e interesses dos usuários e torcedores e melhorar sua experiência de navegação.
              </p>
              <p class="text-justify">
                Os cookies são pequenos arquivos guardados no disco rígido do computador ou em seu celular que servem
                para reconhecer, acompanhar e armazenar a navegação da pessoa na internet.
              </p>
              <p class="text-justify">
                Os cookies são utilizados para calcular a audiência do site, medir padrões de navegação, mapeando os
                hábitos de visita do usuário no site com um todo, também agiliza os preenchimentos de informações nas
                buscas e formulários.
              </p>
              <p class="text-justify">
                O usuário tem opções em relação aos cookies. A aceitação, permanência e existência dos cookies no
                computador do torcedor depende da sua vontade, uma vez que eles podem ser excluídos do computador sempre
                que desejar. O torcedor pode aceitar todos os cookies ou rejeitá-los, tendo a ciência que se optar por
                rejeitá-los sua navegação dentro do site pode ser comprometida em algumas funções.
              </p>
              <h4> Segurança e privacidade </h4>
              <p class="text-justify">
                O União Frederiquense não envia nenhuma mensagem privada aos seus torcedores solicitando em nome do site
                a confirmando de dados, assim como links e arquivos. Apenas se houver uma solicitação de informação por
                parte do usuário.
              </p>
              <h4>Informações coletadas</h4>
              <p class="text-justify">
                Quando você nos envia uma mensagem pelo site, coletamos os seus dados como nome, e-mail e telefone e
                entendemos que você está de acordo com essa coleta de dados.
              </p>
              <h4>Alterações da política de privacidade</h4>
              <p class="text-justify">
                Reservamos o direito de modificar essa política de privacidade a qualquer momento, deste modo,
                recomendamos que a consulte com frequência. As alterações da política de privacidade passarão a vigorar
                a partir da data da sua publicação
              </p>
            </v-col>
          </v-row>
        </div>

      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";

export default {
  name: "Politica",
  components: {Topo, Footer},

  data: () => ({
    title: 'Política de Privacidade',
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Política de Privacidade',
        disabled: true
      },
    ],
  }),
  methods: {},
  metaInfo() {
    return {
      title: `Política de Privacidade | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-politics {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }


  .m-body-view {
    padding: 50px 0 0 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    color: var(--grey);
  }
}
</style>
