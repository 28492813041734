<template>
  <div class="m-view-management">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular
            :size="50"
            indeterminate
        ></v-progress-circular>
      </div>
      <div v-else>
        <div class="m-management-info">
          <v-container>
            <div class="m-title-view">
              <v-row>
                <v-col>
                  <div class="m-body-title">
                    <div class="m-title">
                  <span>GESTÃO
                    <strong>UNIÃO</strong>
                  </span>
                    </div>
                    <div class="m-border"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="m-body-info">
              <div class="m-text-info">
                <p>Com o objetivo de modernizar a gestão do clube, o estatuto foi alterado para que mais pessoas estivessem envolvidas no processo de gestão, com representantes em cada área específica do clube. No total, a gestão é composta pelo presidente, nove vices, além dos conselhos Fiscal e Deliberativo.</p>
              </div>
            </div>
          </v-container>
        </div>
        <div class="m-management-administrative" id="conselho-administrativo">
          <v-container>
            <div class="m-title-view">
              <v-row>
                <v-col>
                  <div class="m-body-title">
                    <div class="m-title">
                  <span>CONSELHO
                    <strong>ADMINISTRATIVO</strong>
                  </span>
                    </div>
                    <div class="m-border"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="m-body-management">
              <v-row v-if="items.administrative">
                <v-col md="3" sm="4" cols v-for="(item, i) in items.administrative" :key="i">
                  <v-card v-if="item.src"
                      class="m-card-management"
                      :key="i"
                      flat
                      height="300px"
                  >
                    <v-img
                        class="white--text align-end"
                        height="300px"
                        :src="item.src">
                      <div class="m-background-card"></div>
                      <v-card-title class="m-group-management d-block">
                        <div class="m-management">{{ `Gestão ${item.year}` }}</div>
                        <div class="m-name">{{ item.name }}</div>
                        <div class="m-office">{{ item.office.description }}</div>
                      </v-card-title>
                    </v-img>
                  </v-card>
                  <v-card v-else class="m-card-management-not-src" :key="i" flat>
                    <v-card-title class="m-group-management d-block">
                      <div class="m-management">{{ `Gestão ${item.year}` }}</div>
                      <div class="m-name">{{ item.name }}</div>
                      <div class="m-office">{{ item.office.description }}</div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </div>
        <div class="m-management-deliberative" id="conselho-deliberativo">
          <v-container>
            <div class="m-title-view">
              <v-row>
                <v-col>
                  <div class="m-body-title">
                    <div class="m-title">
                  <span>CONSELHO
                    <strong>DELIBERATIVO</strong>
                  </span>
                    </div>
                    <div class="m-border"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="m-body-management">
              <v-row v-if="items.deliberative">
                <v-col md="3" sm="4" cols v-for="(item, i) in items.deliberative" :key="i">
                  <v-card v-if="item.src"
                      class="m-card-management"
                      :key="i"
                      flat
                      height="300px"
                  >
                    <v-img
                        class="white--text align-end"
                        height="300px"
                        :src="item.src">
                      <div class="m-background-card"></div>
                      <v-card-title class="m-group-management d-block">
                        <div class="m-management">{{ `Gestão ${item.year}` }}</div>
                        <div class="m-name">{{ item.name }}</div>
                        <div class="m-office">{{ item.office.description }}</div>
                      </v-card-title>
                    </v-img>
                  </v-card>
                  <v-card v-else class="m-card-management-not-src" :key="i" flat>
                    <v-card-title class="m-group-management d-block">
                      <div class="m-management">{{ `Gestão ${item.year}` }}</div>
                      <div class="m-name">{{ item.name }}</div>
                      <div class="m-office">{{ item.office.description }}</div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </div>
        <div class="m-management-fiscal" id="conselho-fiscal">
          <v-container>
            <div class="m-title-view">
              <v-row>
                <v-col>
                  <div class="m-body-title">
                    <div class="m-title">
                  <span>CONSELHO
                    <strong>FISCAL</strong>
                  </span>
                    </div>
                    <div class="m-border"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="m-body-management">
              <v-row v-if="items.fiscal">
                <v-col md="3" sm="4" cols v-for="(item, i) in items.fiscal" :key="i">
                  <v-card v-if="item.src"
                      class="m-card-management"
                      :key="i"
                      flat
                      height="300px"
                  >
                    <v-img
                        class="white--text align-end"
                        height="300px"
                        :src="item.src">
                      <div class="m-background-card"></div>
                      <v-card-title class="m-group-management d-block">
                        <div class="m-management">{{ `Gestão ${item.year}` }}</div>
                        <div class="m-name">{{ item.name }}</div>
                        <div class="m-office">{{ item.office.description }}</div>
                      </v-card-title>
                    </v-img>
                  </v-card>
                  <v-card v-else class="m-card-management-not-src" :key="i" flat>
                    <v-card-title class="m-group-management d-block">
                      <div class="m-management">{{ `Gestão ${item.year}` }}</div>
                      <div class="m-name">{{ item.name }}</div>
                      <div class="m-office">{{ item.office.description }}</div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </div>
        <div class="m-management-statute" id="estatuto" v-if="statute">
          <v-container>
            <div class="m-title-view">
              <v-row>
                <v-col>
                  <div class="m-body-title">
                    <div class="m-title">
                  <span>ESTATUTO
                    <strong>DO CLUBE</strong>
                  </span>
                    </div>
                    <div class="m-border"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="m-body-management">
              <v-row>
                <v-col cols>
                  <p class="text-justify">

                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" sm="4" cols v-for="(item, i) in statute" :key="i">
                  <v-card flat class="m-card-statute">
                    <v-card-text>
                      <div class="m-title-statute">{{ item.title }}</div>
                      <v-btn depressed class="m-btn-download" :href="item.file.download" download target="_blank">
                        <v-icon>mdi-download</v-icon>
                        Download
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </div>
        <div class="m-management-balance" id="balancos" v-if="balance">
          <v-container>
            <div class="m-title-view">
              <v-row>
                <v-col>
                  <div class="m-body-title">
                    <div class="m-title">
                  <span>BALANÇOS
                    <strong>DO CLUBE</strong>
                  </span>
                    </div>
                    <div class="m-border"></div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="m-body-management">
              <v-row>
                <v-col cols>
                  <p class="text-justify">
                    O União Frederiquense de Futebol publica anualmente o balanço patrimonial do Clube. Confira os
                    documentos abaixo
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" sm="4" cols v-for="(item, i) in balance" :key="i">
                  <v-card flat class="m-card-balance">
                    <v-card-text>
                      <div class="m-title-balance">{{ item.title }}</div>
                      <v-btn depressed class="m-btn-download" :href="item.file.download" download target="_blank">
                        <v-icon>mdi-download</v-icon>
                        Download
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";

export default {
  name: "Gestao",
  components: {Topo, Footer},

  data: () => ({
    title: 'Gestão União',
    items: [],
    balance: [],
    statute: [],
    loading: true,
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Gestão União',
        disabled: true
      },
    ],
  }),
  created() {
    this.initialize();
    this.setBalance();
    this.setStatuto();
  },
  methods: {
    parseData,
    async initialize() {
      this.loading = true;
      await this.Api()
          .post("/management", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    async setBalance() {
      this.loading = true;
      await this.Api()
          .post("/balance", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.balance = response.data.balance;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    async setStatuto() {
      this.loading = true;
      await this.Api()
          .post("/statute", {
            id: parseData(this.$store.state.site).id,
          })
          .then((response) => {
            this.statute = response.data.statute;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
  },

  metaInfo() {
    return {
      title: `Gestão União | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-management {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }


  .m-body-view {
    padding: 50px 0;
    color: var(--grey);

    .m-title-view {
      padding-top: 50px;
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-management-info {
      .m-body-info {
        .m-text-info {
          text-align: justify;
          line-height: 1.3;
          font-size: 16px;
        }
      }
    }

    .m-btn-download {
      text-align: center;
      color: var(--white);
      height: 50px;
      border-radius: 10px;
      font-weight: 600;
      background-color: var(--cor-principal);
    }

    .m-management-statute {
      .m-body-management {
        .m-card-statute {
          border-radius: 10px;
          background-color: var(--faint-gray);

          .v-card__text {
            padding: 24px;
            color: var(--grey) !important;

            .m-title-statute {
              min-height: 30px;
              font-size: 16px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .m-body-management {
      .m-card-management {
        border-radius: 10px;

        .v-image__image {
          &::after {
            background: linear-gradient(
                    to bottom,
                    transparent 35%,
                    var(--dark) 100%
            );
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
          }
        }

        .m-group-management {
          letter-spacing: initial;
          word-break: normal;
          padding: 24px;
          color: var(--grey) !important;
          .m-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 1.2;
            text-transform: uppercase;
            min-height: 20px;
            max-height: 20px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .m-office {
            font-size: 12px;
            min-height: 30px;
            line-height: 1.2;
          }

          .m-management{
            font-size: 12px;
            line-height: 1.2;
          }
        }

        &::before {
          border-radius: 10px !important;
        }
      }
      .m-card-management-not-src{
        border-radius: 10px;
        background-color: var(--faint-gray);

        .m-group-management {
          letter-spacing: initial;
          word-break: normal;
          padding: 24px;
          color: var(--grey) !important;
          .m-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 1.2;
            text-transform: uppercase;
            min-height: 40px;
            max-height: 40px;
            max-width: 100%;
          }

          .m-office {
            font-size: 12px;
            min-height: 30px;
            line-height: 1.2;
          }

          .m-management{
            font-size: 12px;
            line-height: 1.2;
          }
        }
      }
    }

    .m-management-balance {
      .m-body-management {
        .m-card-balance {
          border-radius: 10px;
          background-color: var(--faint-gray);

          .v-card__text {
            text-align: center;
            padding: 24px;
            color: var(--grey) !important;

            .m-title-balance {
              min-height: 30px;
              font-size: 16px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
</style>
