<template>
  <div class="m-view-see-list">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular
            :size="50"
            indeterminate
        ></v-progress-circular>
      </div>
      <v-container v-else>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>ELENCO</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col md="4" sm="5" cols>
            <v-img class="m-image-player" :src="items.src"></v-img>
          </v-col>
          <v-col md="8" sm="7" cols>
            <div class="m-body-info">
              <div class="m-info" v-if="items.name">
                <div class="m-title-info"><strong>Nome Completo</strong></div>
                <div class="m-title-subtitle">{{ items.name }}</div>
              </div>
              <div class="m-info" v-if="items.number && items.number > 0">
                <div class="m-title-info"><strong>Camisa</strong></div>
                <div class="m-title-subtitle">{{ items.number }}</div>
              </div>
              <div class="m-info" v-if="items.profession">
                <div class="m-title-info"><strong>{{`${items.subcategory.id === 1 ? 'Posição' : 'Profissão'}`}}</strong></div>
                <div class="m-title-subtitle">{{ items.profession.description }}</div>
              </div>
              <div class="m-info" v-if="items.naturalidade && items.naturalidade.id !== null">
                <div class="m-title-info"><strong>Cidade natal</strong></div>
                <div class="m-title-subtitle">{{ `${items.naturalidade.description}/${items.naturalidade.uf}` }}</div>
              </div>
              <div class="m-info" v-if="items.height">
                <div class="m-title-info"><strong>Altura</strong></div>
                <div class="m-title-subtitle">{{ `${items.height}m` }}</div>
              </div>
              <div class="m-info" v-if="items.weight">
                <div class="m-title-info"><strong>Peso</strong></div>
                <div class="m-title-subtitle">{{ `${items.weight}kg` }}</div>
              </div>
              <div class="m-info" v-if="items.birth_date">
                <div class="m-title-info"><strong>Data de nascimento</strong></div>
                <div class="m-title-subtitle">{{ items.birth_date }}</div>
              </div>
              <div class="m-info" v-if="items.description">
                <div class="m-title-info"><strong>Descrição</strong></div>
                <div class="m-title-subtitle">{{ items.description }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="m-title-view mt-9">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span v-if="items.subcategory.id === 1">OUTROS<strong> JOGADORES</strong></span>
                  <span v-if="items.subcategory.id === 2">CONHEÇA MAIS A <strong> EQUIPE TÉCNICA</strong></span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col>
            <v-slide-group
                v-model="modelPlayers"
                center-active
                show-arrows
            >
              <v-slide-item
                  v-for="(play, i) in similar"
                  :key="i"
                  v-slot="{ active, toggle }"
              >
                <v-card
                    class="ma-4"
                    width="300"
                    flat
                    :class="i % 2 === 0 ? 'm-card-players-par' : 'm-card-players-impar'"
                    @click="toggle; initialize()"
                    :to="`/elenco/${play.id}/${play.subcategory.id}/${convertToUrl(play.name)}`"
                >
                  <v-img :src="play.src"></v-img>

                  <v-card-text style="position: relative">
                    <v-btn v-if="Number(play.number) > 0 && play.number"
                        class="m-btn-number"
                        dark
                        absolute
                        top
                        right
                        fab
                        small
                        v-html="play.number"
                    >
                    </v-btn>
                    <div class="m-player-name" v-html="play.name"></div>
                    <div class="m-player-profession" v-html="play.profession.description"></div>
                  </v-card-text>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {corta_string, parseData, getCapa, removeTag, convertToUrl} from "../utils";
export default {
  name: "Elenco",

  components: {Topo, Footer},

  data: () => ({
    title: 'Ver Elenco',
    loading: true,
    items: [],
    similar: [],
    model: null,
    modelPlayers: null,
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Elenco',
        disabled: false,
        href: '/elenco',
      },
      {
        text: '',
        disabled: true,
        href: '/elenco/1',
      },
    ],
  }),

  async created() {
    await this.initialize();
    await this.similarList();
  },

  methods: {
    corta_string, parseData, getCapa, removeTag, convertToUrl,
    async initialize() {
      this.loading = true;
      const data = {id: parseData(this.$store.state.site).id, codigoJogador: Number(this.$route.params.id)};
      await this.Api().post('/view_cast', data).then(response => {
        this.items = response.data;
        this.breadcrumbs[2].text = this.corta_string(response.data.name, 35);
        this.loading = false;
      }).catch(error => {
        console.log(error)
        this.loading = false;
      });
    },
    async similarList() {
      this.loading = true;
      const data = {'id': parseData(this.$store.state.site).id, 'category': Number(this.items.category.id), subcategory: Number(this.$route.params.subcategory)};
      await this.Api().post('/similar_cast', data).then(response => {
        this.similar = response.data;
        this.loading = false;
      }).catch(error => {
        console.log(error)
        this.loading = false;
      });
    },
  },

  metaInfo() {
    return {
      title: this.items.title ? 'Elenco | ' + this.items.title + ' | ' + parseData(this.$store.state.site).name : 'Elenco',
    }
  },
}
</script>

<style lang="scss">
.m-view-see-list {
  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;
    color: var(--grey);
    font-size: 16px;
    line-height: 1.4;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-image-player {
      height: 400px;
      border-radius: 10px;

      .v-image__image {
        height: 400px;
      }
    }

    .m-body-info {

      .m-info {
        background-color: var(--faint-gray);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        width: 100%;
      }
    }

    .m-card-players-par {

      background-color: var(--cor-principal);
      border-radius: 10px;

      .m-btn-number {
        font-size: 18px;
        font-weight: 600;
        color: var(--cor-principal);
        background-color: var(--white);
      }

      .m-player-name {
        color: var(--white);
        font-size: 16px;
      }

      .m-player-profession {
        color: var(--white);
        font-size: 14px;
      }
    }

    .m-card-players-impar {
      @extend .m-card-players-par;
      background-color: var(--cor-segundaria);
    }
  }
}
@media (min-width: 960px) {
  .m-view-see-list {
    .m-body-view {
      .m-body-info {
        .m-info {
          width: 50%;
        }
      }
    }
  }
}

</style>
