<template>
  <div class="m-page-ver-foto">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular
            :size="50"
            indeterminate
        ></v-progress-circular>
      </div>
      <v-container v-else>
        <div v-if="!loading">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>
                    <strong>{{ items.title }}</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols>
              <div class="m-date-photo">
                <v-icon>mdi-calendar-month-outline</v-icon>
                {{ items.date }}
              </div>
              <div class="sharethis-inline-share-buttons"></div>
              <div class="m-descrication-photo" v-html="items.descrication"></div>
            </v-col>
          </v-row>
          <v-row v-if="items.image">
            <v-col md="3" sm="3" cols class="images-wrapper" v-for="(item, i) in items.image" :key="i">
              <div class="image image-img" @click="index = i" :style="{ backgroundImage: 'url(' + item.src + ')' }"></div>
            </v-col>
            <CoolLightBox
                :items="items.image"
                :index="index"
                @close="index = null">
            </CoolLightBox>
          </v-row>
        </div>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import {parseData, corta_string} from "../utils";

export default {
  name: "Fotos",

  components: {Topo, Footer, CoolLightBox},

  data: () => ({
    title: 'Fotos',
    loading: true,
    items: [],
    model: null,
    index: null,
    host: window.location.href,
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Galerias de Fotos',
        disabled: false,
        href: '/fotos/1',
      },
      {
        text: '',
        disabled: true,
        href: '/foto/1',
      },
    ],
  }),

  created() {
    this.initialize();
  },

  methods: {
    parseData, corta_string,
    async initialize() {
      this.loading = true;
      const data = {'id': parseInt(this.$route.params.id)};
      await this.Api().post('/view_photos', data).then(response => {
        this.items = response.data;
        this.breadcrumbs[2].text = this.corta_string(response.data.title, 35);
        this.loading = false;
      }).catch(error => {
        console.log(error)
        this.loading = false;
      });
    },
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        src: 'https://platform-api.sharethis.com/js/sharethis.js#property=5ead6cc6b892d40012e34437&product=sop',
        async: true,
        body: true
      },
    ],
  },
  metaInfo() {
    return {
      title: this.items.title ? 'Galerias de Fotos | ' + this.items.title + ' | ' + parseData(this.$store.state.site).name : 'Galerias de Fotos',
    }
  }
}
</script>

<style lang="scss">
.m-page-ver-foto {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;

    .m-body-title {
      .m-title {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 24px;
        color: var(--cor-principal);
      }

      .m-border {
        margin-top: 5px;
        width: 135px;
        height: 5px;
        background-color: var(--cor-principal);
      }
    }

    .m-descrication-photo {
      font-size: 16px;
      text-align: center;
    }

    .m-date {
      margin: 10px 0;
      align-content: center;
      text-align: center;
      font-size: 16px;
      color: var(--grey);

      i {
        font-size: 16px;
        color: var(--grey);
      }
    }

    .image-cover {
      .img-cover {
        border-radius: 10px;
        max-height: 600px;
        max-width: 100%;

        .v-image__image--cover {
          background-size: contain !important;
        }
      }
    }

    .fonte-news {
      margin-top: 10px;
      font-size: 15px;
    }

    .subtitle {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    .image-img {
      border-radius: 20px;
      background-size: cover;
      height: 200px;
      width: 100%;
      cursor: pointer;
      margin-right: 24px;
    }

    .image-img:last-child {
      margin-right: 0;
    }

    .share {
      text-align: center;

      .link-share {
        a {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
