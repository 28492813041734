<template>
  <div class="m-view-arena">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>Arena
                    <strong>União Frederiquense</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-arena">
          <v-row>
            <v-col>
              <p class="text-justify">O título de "Clube que mais cresce" não é à toa ao União Frederiquense. Ao longo
                dos últimos anos o clube vem se estruturando e, cada vez mais, melhorando as condições para receber o
                torcedor. Em 2021, iniciaram as obras para ampliar a capacidade do estádio, que hoje pode receber
                cerca de 3 mil torcedores.</p>
              <p class="text-justify">O ambicioso projeto da equipe visa ofertar ao torcedor as melhores condições
                possíveis para assistir a uma partida do Tricolor. Por isso, foram instaladas estruturas para cobrir
                área destinada ao sócio-remido patrimonial, em breve o clube receberá as poltronas que também serão
                instaladas no local. Cada sócio-remido terá o direito a duas poltronas e, além disso, as cabines de
                imprensa também serão ampliadas de nove, para 18 espaços e banheiros exclusivos para os profissionais de
                comunicação.</p>
              <p class="text-justify">Na arquibancada geral, também está sendo realizadas obras de ampliação, para que o
                clube atinja o número total estipulado.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img class="mb-6" src="../assets/arena_1.jpg"></v-img>
              <v-img class="mb-6" src="../assets/arena_2.jpg"></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="6" cols>
              <div class="m-arena-location">

                <p><strong>Arena do União</strong><br>
                  Estrada Santo Caeran, S/N - B. Faguense<br>
                  Frederico Westphalen-RS 98400-000<br>
                  (55) 3744-2333</p>
              </div>
            </v-col>
            <v-col md="6" sm="6" cols>
              <div class="m-arena-location">
                <p><strong>Endereço de Correspondência</strong><br>
                  Rua Rui Barbosa, 784 - Sala 02, Centro<br>
                  98400-000, Frederico Westphalen-RS<br>
                  (55) 3744-2333</p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col col cols>
              <v-card flat class="m-card-info" to="/fundacao">
                <v-card-text>
                  <div class="m-title">Fundação</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col col cols>
              <v-card flat class="m-card-info" to="/simbolos">
                <v-card-text>
                  <div class="m-title">Símbolos</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col col cols>
              <v-card flat class="m-card-info" to="/gestao">
                <v-card-text>
                  <div class="m-title">Gestão</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col col cols>
              <v-card flat class="m-card-info" to="/hinos">
                <v-card-text>
                  <div class="m-title">Nosso Hino</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="m-title-view m-title-arena" id="m-mapa">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>Como chegar na
                    <strong>Arena do União</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <gmaps-map class="m-mapa" :options="mapOptions">
        <gmapsMarker v-for="(item, i) in maps" :key="i" :options="item.options"></gmapsMarker>
      </gmaps-map>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";
import {gmapsMap, gmapsMarker} from 'x5-gmaps'

export default {
  name: "Arena",
  components: {Topo, Footer, gmapsMap, gmapsMarker},

  data: () => ({
    title: 'Arena União Frederiquense',
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Arena União Frederiquense',
        disabled: true
      },
    ],
    mapOptions: {
      center: {lat: -27.3639477, lng: -53.4248185},
      zoom: 15,
    },
    maps: [
      {options: {position: {lat: -27.3639477, lng: -53.4248185}}},
    ],
  }),
  methods: {
    parseData,
    getLocation() {
      if (!navigator.geolocation) {
        console.log('Não foi possível obter a sua geolocalização');
        return;
      }

      console.log('obtendo geolocalização...');
      const latitude = parseData(this.$store.state.site).latitude;
      const longitude = parseData(this.$store.state.site).longitude;
      navigator.geolocation.getCurrentPosition(function (position) {
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${position.coords.latitude},${position.coords.longitude}&destination=${latitude},${longitude}&travelmode=driving&hl=pt-br`, '_blank');
      });
    },
  },
  metaInfo() {
    return {
      title: `Arena União Frederiquense | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-arena {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }


  .m-body-view {
    padding: 50px 0 0 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    .m-title-arena{
      margin-top: 50px;
      margin-bottom: 10px;
    }

    .m-body-arena {
      line-height: 1.3;

      .v-image {
        border-radius: 10px;
      }

      .m-arena-location {
        color: var(--grey);
      }

      .m-card-info {
        text-align: center;
        border-radius: 10px;
        background-color: var(--faint-gray);
        transition: all 0.5s;

        .m-title {
          font-weight: 600;
          text-transform: uppercase;
          color: var(--grey);
          transition: all 0.5s;
        }

        &:hover, &:focus, &:active {
          .m-title {
            color: var(--white);
          }

          background-color: var(--cor-principal);
        }
      }
    }

    .m-mapa {
      height: 450px;
      margin-top: 30px;
    }
  }
}
</style>
