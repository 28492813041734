<template>
  <div class="m-view-see-news">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <div class="text-center" v-if="loading">
        <v-progress-circular
            :size="50"
            indeterminate
        ></v-progress-circular>
      </div>
      <v-container v-else>
        <v-row>
          <v-col md="3" cols class="d-none d-md-block d-lg-block d-xl-block">
            <div class="m-card-fixed">
              <div class="m-subtitle-news">
                <div class="m-title">
                  <span>Últimas
                    <strong>Notícias</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
              <v-card flat class="m-card-last-news" v-for="(item,i) in similar" :key="i"
                      :to="`/noticia/${item.id}/${convertToUrl(item.title)}`" @click="initialize()">
                <div class="d-flex mb-4">
                  <v-img class="m-image-news" height="100px" width="100px"
                         :src="item.image ? getCapa(item.image).img : '../assets/img_padrao.png'"></v-img>
                  <div class="m-info-news">
                    <div class="m-title-news" v-html="item.title"></div>

                    <div class="group m-group-date align-content-center">
                      <v-icon dark>
                        mdi-calendar
                      </v-icon>
                      <span>{{ item.date }}</span>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
          <v-col md="9" sm="12" cols>
            <v-row>
              <v-col cols>
                <div class="m-title-news text-left" v-html="items.title"></div>
                <div class="m-date-news" v-html="`Publicado ${items.date}`"></div>
                <div class="m-resume-news" v-html="items.resume"></div>
                <div class="link-share">
                  <ShareNetwork
                      network="facebook"
                      :url="`https://uniaofw.com.br/json/share?id=${items.id}`"
                      :title="items.title"
                      :description="items.summary"
                      :media="items.image ? items.image[0].src : ''">
                    <v-btn icon>
                      <v-icon icon>mdi-facebook</v-icon>
                    </v-btn>
                  </ShareNetwork>
                  <ShareNetwork
                      network="email"
                      :url="host"
                      :title="items.title"
                      :description="items.summary">
                    <v-btn icon>
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </ShareNetwork>
                  <ShareNetwork
                      network="whatsApp"
                      :url="host"
                      :title="items.title"
                      :description="items.summary">
                    <v-btn icon>
                      <v-icon>mdi-whatsapp</v-icon>
                    </v-btn>
                  </ShareNetwork>
                  <ShareNetwork
                      network="twitter"
                      :url="host"
                      :title="items.title">
                    <v-btn icon>
                      <v-icon>mdi-twitter</v-icon>
                    </v-btn>
                  </ShareNetwork>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <v-img class="m-image-cover"
                       :lazy-src="items.image ? getCapa(items.image).src : '../assets/img_padrao.png'"
                       :src="items.image ? getCapa(items.image).src : '../assets/img_padrao.png'"></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <div class="m-text-news" v-html="items.text"></div>
                <div v-if="items.fonte" class="m-fonte-news" v-html="`Fonte: ${ items.fonte }`"></div>
              </v-col>
            </v-row>
            <v-row v-if="items.video">
              <v-col cols>
                <div class="m-subtitle-news">
                  <div class="m-title">
                  <span>
                    <strong>Vídeos</strong>
                  </span>
                  </div>
                  <div class="m-border"></div>
                </div>
                <div class="images-wrapper">
                  <v-slide-group v-model="modelVideo" active-class="success" center-active show-arrows>
                    <v-slide-item v-for="(item, i) in items.video" :key="i" v-slot="{ active, toggle }">
                      <div class="image image-video" @click="indexVideo = i; toggle"
                           :style="{ backgroundImage: 'url(' + item.miniature + ')' }">
                        <v-icon>mdi-play-circle-outline</v-icon>
                      </div>
                    </v-slide-item>
                  </v-slide-group>
                </div>
              </v-col>
              <CoolLightBox
                  :items="items.video"
                  :index="indexVideo"
                  @close="indexVideo = null">
              </CoolLightBox>
            </v-row>
            <v-row v-if="items.image">
              <v-col cols>
                <div class="m-subtitle-news">
                  <div class="m-title">
                  <span>
                    <strong>Fotos</strong>
                  </span>
                  </div>
                  <div class="m-border"></div>
                </div>
                <div class="images-wrapper">
                  <v-slide-group v-model="model" active-class="success" center-active show-arrows>
                    <v-slide-item v-for="(item, i) in items.image" :key="i" v-slot="{ active, toggle }">
                      <div class="image m-image-img" @click="index = i; toggle"
                           :style="{ backgroundImage: 'url(' + item.src + ')' }"></div>
                    </v-slide-item>
                  </v-slide-group>
                </div>
              </v-col>
              <CoolLightBox
                  :items="items.image"
                  :index="index"
                  :effect="'fade'"
                  :fullScreen="true"
                  @close="index = null">
              </CoolLightBox>
            </v-row>
            <v-row v-if="items.audio">
              <v-col cols>
                <div class="m-subtitle-news">
                  <div class="m-title">
                  <span>
                    <strong>Áudios</strong>
                  </span>
                  </div>
                  <div class="m-border"></div>
                </div>
                <v-row>
                  <v-col md="4" v-for="(item, i) in items.audio" :key="i">
                    <div class="card-audio-news">
                      <vuetify-audio :file="item.audio" flat></vuetify-audio>
                      <div class="title-audio">{{ item.title }}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols class="d-block d-md-none d-lg-none d-xl-none">
            <div class="m-card-fixed">
              <div class="m-subtitle-news">
                <div class="m-title">
                  <span>Últimas
                    <strong>Notícias</strong>
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
              <v-card flat class="m-card-last-news" v-for="(item,i) in similar" :key="i"
                      :to="`/noticia/${item.id}/${convertToUrl(item.title)}`" @click="initialize()">
                <div class="d-flex mb-4">
                  <v-img class="m-image-news" height="100px" width="100px"
                         :src="item.image ? getCapa(item.image).img : '../assets/img_padrao.png'"></v-img>
                  <div class="m-info-news">
                    <div class="m-title-news" v-html="item.title"></div>

                    <div class="group m-group-date align-content-center">
                      <v-icon dark>
                        mdi-calendar
                      </v-icon>
                      <span>{{ item.date }}</span>
                    </div>
                  </div>
                </div>

              </v-card>
              <div class="sharethis-inline-share-buttons"></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import VuetifyAudio from "vuetify-audio";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import {corta_string, parseData, getCapa, removeTag, convertToUrl} from "../utils";

export default {
  name: "Notícia",

  components: {Topo, Footer, VuetifyAudio, CoolLightBox},

  data: () => ({
    title: 'Notícia',
    loading: true,
    items: [],
    similar: [],
    model: null,
    modelVideo: null,
    index: null,
    indexVideo: null,
    host: window.location.href,
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Notícias',
        disabled: false,
        href: '/noticias/1',
      },
      {
        text: '',
        disabled: true,
        href: '/noticias/1',
      },
    ],
  }),

  async mounted() {
    await this.initialize();
    await this.similarNews();
  },

  methods: {
    corta_string, parseData, getCapa, removeTag, convertToUrl,
    async initialize() {
      this.loading = true;
      const data = {'id': parseInt(this.$route.params.id)};
      await this.Api().post('/view_news', data).then(response => {
        this.items = response.data;
        this.breadcrumbs[2].text = this.corta_string(response.data.title, 35);
        this.loading = false;
      }).catch(error => {
        console.log(error)
        this.loading = false;
      });
    },
    async similarNews() {
      this.loading = true;
      const data = {'id': parseData(this.$store.state.site).id, 'category': this.items.category.id};
      await this.Api().post('/view_latest_news', data).then(response => {
        this.similar = response.data;
        this.loading = false;
      }).catch(error => {
        console.log(error)
        this.loading = false;
      });
    },
  },

  metaInfo() {
    return {
      title: this.items.title ? 'Notícia | ' + this.items.title + ' | ' + parseData(this.$store.state.site).name : 'Notícia',
      meta: [
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: this.items.title},
        {
          name: 'twitter:description',
          content: removeTag(this.items.resume)
        },
        {
          name: 'twitter:image',
          content: this.items.image ? getCapa(this.items.image).src : '../assets/img_padrao.png'
        },
        {property: 'og:title', content: this.items.title},
        {property: 'og:site_name', content: 'União Frederiquense de Futebol'},
        {
          property: 'og:url',
          content: window.location.href
        },
        {property: 'og:type', content: 'website'},
        {
          property: 'og:image',
          content: this.items.image ? getCapa(this.items.image).src : '../assets/img_padrao.png'
        },
        {
          property: 'og:description',
          content: removeTag(this.items.resume)
        }
      ]
    }
  },
}
</script>

<style lang="scss">
.m-view-see-news {
  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0;
    color: var(--dark);
    font-size: 16px;
    line-height: 1.4;
    text-align: justify;


    .m-card-fixed {
      .m-card-last-news {
        text-decoration: none;
        text-align: left;
        color: var(--grey);

        .m-image-news {
          border-radius: 10px;
          margin-right: 10px;
        }

        .m-info-news {
          .m-title-news {
            font-size: 14px;
            line-height: 1.2;
            min-height: 65px;
            max-height: 65px;
            max-width: 100%;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            transition: all 0.5s;
          }

          .m-group-date {
            font-size: 12px;
            line-height: 0;

            .v-icon {
              font-size: 16px;
              color: var(--grey);
              margin-right: 5px;
            }
          }
        }

        &:hover, &:active, &:focus {
          .m-info-news {
            .m-title-news {
              color: var(--cor-principal);
            }
          }
        }
      }
    }


    .link-share {
      a {
        text-decoration: none;
      }
    }
    .m-title-news {
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.3;
      text-transform: uppercase;
      color: var(--cor-principal);
    }

    .m-date-news {
      margin-bottom: 30px;
    }

    .m-resume-news {
      color: var(--dark);
    }

    .m-fonte-news{
      margin-top: 30px;
      font-weight: bold;
    }

    .m-image-cover {
      border-radius: 10px;
      height: 600px;
    }

    .m-image-img {
      border-radius: 10px;
      background-size: cover;
      height: 150px;
      width: 150px;
      cursor: pointer;
      margin-right: 24px;
    }

    .m-image-img:last-child {
      margin-right: 0;
    }

    .m-image-video {
      background-size: cover;
      border-radius: 10px;
      height: 150px;
      width: 150px;
      cursor: pointer;
      margin-right: 24px;
      transition: all 0.2s ease-in;

      .v-icon {
        display: none;
        color: red;
        z-index: 1;
        top: calc(50% - 25px);
        font-size: 50px;
      }

      &:hover {
        .v-icon {
          display: flex;
        }
      }
    }

    .m-image-video:last-child {
      margin-right: 0;
    }

    .m-subtitle-news {
      margin-bottom: 15px;

      .m-title {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        color: var(--cor-principal);
      }

      .m-border {
        margin-top: 5px;
        width: 50px;
        height: 5px;
        background-color: var(--cor-principal);
      }
    }

    .m-text-news {
      p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 1.4;
      }
    }

    .card-audio-news {
      padding: 20px;
      background-color: var(--faint-gray);
      border-radius: 20px;

      .v-card__text {
        padding: 0;
        background-color: var(--faint-gray);
      }

      .title-audio {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}


</style>
