import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import Api from './services/Api'
import VueMeta from 'vue-meta'
import x5GMaps from 'x5-gmaps'
import VueHead from 'vue-head'
import VueYoutube from 'vue-youtube'

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);


Vue.use(VueYoutube)
Vue.use(VueHead)
Vue.use(VueMeta);
Vue.prototype.Api = Api;
Vue.config.productionTip = false;
Vue.use(x5GMaps, { key:  'AIzaSyCG8OIPbBsMO4Vt_QyV2qWALyq51YwR-hk', libraries: ['places'] });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
